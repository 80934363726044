'use strict';
app.factory("eInvoiceService", function ($rootScope, $http)
{
    let eInvoiceServiceInternalFactory = {
        //Authorization Properties
        /** @type { {IRBMServerUrl: string, token: string, tokenGenerateTime: Date, tokenExpireInSecond: Number, tokenExpireSafetyMarginInSecond: Number, clientTIN: string, versionID: string, IRBMServerDelay: Number} } */
        _authObject: {
            IRBMServerUrl: ($rootScope.isLiveProd === true) ? "https://api.myinvois.hasil.gov.my" : "https://preprod-api.myinvois.hasil.gov.my",
            token: "",
            tokenGenerateTime: new Date(2000, 0, 1),
            tokenExpireInSecond: 0,
            tokenExpireSafetyMarginInSecond: 300,
            clientTIN: "",
            versionID: "1.1",
            IRBMServerDelay: 5 //IRBM's server is lagging behind cause our issue date earlier than IRBM's timing, introduce a delay in seconds to compensate
        },

        /**
         * Check if input string is null or whitespace
         * @param {string} input - Input string.
         * @returns {boolean}
         */
        _isNullOrWhitespace: function (input)
        {
            return (typeof input != "string") || !input || !input.trim();
        },

        /**
         * Decode JWT token requested from IRBM API
         * @param {string} token - Token, optional, default to current session's token if not pass in.
         * @returns {Object}
         */
        _decodeToken: function (token)
        {
            let that = this;

            //take token from param first, then only current session's token
            let _token = (typeof token == "undefined") ? (that._isNullOrWhitespace(that._authObject.token) ? "" : that._authObject.token) : ((typeof token == "string") ? token : "");

            if (that._isNullOrWhitespace(_token))
            {
                return void 0;
            }
            else
            {
                return JSON.parse(decodeURIComponent(window.atob(_token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')).split('').map(function (c)
                {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join('')));
            }
        },

        /**
         * Remove invalid character from input string.
         * @param {string} input - Input string.
         * @returns {string}
         */
        _sanitiseInput: function (input)
        {
            if (typeof input === "string")
            {
                //remove non-UTF8 character
                let output = "";

                for (var i = 0; i < input.length; i++)
                {
                    if (input.charCodeAt(i) <= 127)
                    {
                        output += input.charAt(i);
                    }
                }

                return output.replaceAll("\"", "").replaceAll("'", "").replaceAll("<", "").replaceAll(">", "").replaceAll("&", "");
            }
            else
            {
                return input;
            }
        },

        /**
         * Check if input string is RFC 5321 compliant Email, follow RFC 5321 standard as it is stricter
         * @param {string} input - Input string.
         * @returns {boolean}
         */
        _isValidRFC5321Email: function (input)
        {
            let that = this;
            let regex = new RegExp("([!#-\'*+\/-9=?A-Z^-~-]+(\\.[!#-\'*+\/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \\t]|(\\\\[\\t -~]))+\")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])");
            return !that._isNullOrWhitespace(input) && regex.test(input) && input.length <= 320 && !input.includes(";");
        },

        /**
         * Check if input string is E.164 compliant Phone Number
         * @param {string} input - Input string.
         * @returns {boolean}
         */
        _isValidE164PhoneNumber: function (input)
        {
            let that = this;
            if (!that._isNullOrWhitespace(input))
            {
                try
                {
                    //default to search in malaysia only, for other country requires user to enter country code ie +1
                    const Phone = libphonenumber.parsePhoneNumber(input, "MY");

                    if (Phone.isValid())
                    {
                        if (!that._isNullOrWhitespace(Phone.number))
                        {
                            return true;
                        }
                    }
                }
                catch { }
            }

            return false;
        },

        /**
         * Attempt to extract E.164 compliant Phone Number from input, return empty string if fail
         * @param {string} input - Input string.
         * @returns {string}
         */
        _extractValidE164PhoneNumber: function (input)
        {
            let that = this;
            if (!that._isNullOrWhitespace(input))
            {
                try
                {
                    //default to search in malaysia only, for other country requires user to enter country code ie +1
                    const Phone = libphonenumber.parsePhoneNumber(input, "MY");

                    if (Phone.isValid())
                    {
                        if (!that._isNullOrWhitespace(Phone.number))
                        {
                            return Phone.number;
                        }
                    }
                }
                catch { }
            }

            return "";
        },

        /**
         * Generate hash from string input
         * @param {string} input - Input string.
         * @param {string} encoding - hex, base64.
         * @returns {string}
         */
        _generateHashFromString: function (input, encoding)
        {
            if (typeof input != "string" || typeof encoding != "string" || (encoding != "hex" && encoding != "base64"))
            {
                return null;
            }
            else
            {
                if (encoding == "hex")
                {
                    //hexadecimal
                    return sha256.hex(input);
                }
                else if (encoding == "base64")
                {
                    //base64
                    return btoa(String.fromCharCode(...(sha256.array(input))));
                }
            }
        },

        /**
         * Map Quarto country code to IRBM country code. For now only support limited list of country, will add as required.
         * @param {string} input - Input string.
         * @returns {string}
         */
        _generateIRBMCountryCode: function (input)
        {
            if (typeof input == "string")
            {
                let _input = input.toUpperCase().trim();

                if (_input == "AF") return "AFG"; //AFGHANISTAN
                if (_input == "AL") return "ALB"; //ALBANIA
                if (_input == "DZ") return "DZA"; //ALGERIA
                if (_input == "AS") return "ASM"; //AMERICAN SAMOA
                if (_input == "AD") return "AND"; //ANDORA
                if (_input == "AO") return "AGO"; //ANGOLA
                if (_input == "AI") return "AIA"; //ANGUILLA
                if (_input == "AQ") return "ATA"; //ANTARCTICA
                if (_input == "AG") return "ATG"; //ANTIGUA AND BARBUDA
                if (_input == "AR") return "ARG"; //ARGENTINA
                if (_input == "AM") return "ARM"; //ARMENIA
                if (_input == "AW") return "ABW"; //ARUBA
                if (_input == "AU") return "AUS"; //AUSTRALIA
                if (_input == "AT") return "AUT"; //AUSTRIA
                if (_input == "AZ") return "AZE"; //AZERBAIDJAN
                if (_input == "BS") return "BHS"; //BAHAMAS
                if (_input == "BH") return "BHR"; //BAHRAIN
                if (_input == "BD") return "BGD"; //BANGLADESH
                if (_input == "BB") return "BRB"; //BARBADOS
                if (_input == "BY") return "BLR"; //BELARUS
                if (_input == "BE") return "BEL"; //BELGIUM
                if (_input == "BZ") return "BLZ"; //BELIZE
                if (_input == "BJ") return "BEN"; //BENIN
                if (_input == "BM") return "BMU"; //BERMUDA
                if (_input == "BT") return "BTN"; //BHUTAN
                if (_input == "BO") return "BOL"; //BOLIVIA
                if (_input == "BQ") return "BES"; //BONAIRE, SINT EUSTATIUS AND SABA
                if (_input == "BA") return "BIH"; //BOSNIA AND HERZEGOVINA
                if (_input == "BW") return "BWA"; //BOTSWANA
                if (_input == "BV") return "BVT"; //BOUVET ISLAND
                if (_input == "BR") return "BRA"; //BRAZIL
                if (_input == "IO") return "IOT"; //BRITISH INDIAN OCEAN TERRITORY
                if (_input == "BN") return "BRN"; //BRUNEI DARUSSALAM
                if (_input == "BG") return "BGR"; //BULGARIA
                if (_input == "BF") return "BFA"; //BURKINA FASO
                if (_input == "BI") return "BDI"; //BURUNDI
                if (_input == "KH") return "KHM"; //CAMBODIA
                if (_input == "CM") return "CMR"; //CAMEROON
                if (_input == "CA") return "CAN"; //CANADA
                if (_input == "CV") return "CPV"; //CAPE VERDE
                if (_input == "KY") return "CYM"; //CAYMAN ISLANDS
                if (_input == "CF") return "CAF"; //CENTRAL AFRICAN REPUBLIC
                if (_input == "TD") return "TCD"; //CHAD
                if (_input == "CL") return "CHL"; //CHILE
                if (_input == "CN") return "CHN"; //CHINA
                if (_input == "CX") return "CXR"; //CHRISTMAS ISLANDS
                if (_input == "CC") return "CCK"; //COCOS ISLAND
                if (_input == "CO") return "COL"; //COLOMBIA
                if (_input == "KM") return "COM"; //COMOROS
                if (_input == "CG") return "COG"; //CONGO
                if (_input == "CD") return "COD"; //CONGO, THE DEMOCRATIC REPUBLIC
                if (_input == "CK") return "COK"; //COOK ISLANDS
                if (_input == "CR") return "CRI"; //COSTA RICA
                if (_input == "CI") return "CIV"; //COTE D'IVOIRE
                if (_input == "HR") return "HRV"; //CROATIA
                if (_input == "CU") return "CUB"; //CUBA
                if (_input == "CW") return "CUW"; //CURACAO
                if (_input == "CY") return "CYP"; //CYPRUS
                if (_input == "CZ") return "CZE"; //CZECH REPUBLIC
                if (_input == "DK") return "DNK"; //DENMARK
                if (_input == "DJ") return "DJI"; //DJIBOUTI
                if (_input == "DM") return "DMA"; //DOMINICA
                if (_input == "DO") return "DOM"; //DOMINICAN REPUBLIC
                if (_input == "EC") return "ECU"; //ECUADOR
                if (_input == "EG") return "EGY"; //EGYPT
                if (_input == "SV") return "SLV"; //EL SALVADOR
                if (_input == "GQ") return "GNQ"; //EQUATORIAL GUINEA
                if (_input == "ER") return "ERI"; //ERITREA
                if (_input == "EE") return "EST"; //ESTONIA
                if (_input == "ET") return "ETH"; //ETHIOPIA
                if (_input == "FK") return "FLK"; //FALKLAND ISLANDS (MALVINAS)
                if (_input == "FO") return "FRO"; //FAEROE ISLANDS
                if (_input == "FJ") return "FJI"; //FIJI
                if (_input == "FI") return "FIN"; //FINLAND
                if (_input == "FR") return "FRA"; //FRANCE
                if (_input == "GF") return "GUF"; //FRENCH GUIANA
                if (_input == "PF") return "PYF"; //FRENCH POLYNESIA
                if (_input == "TF") return "ATF"; //FRENCH SOUTHERN TERRITORIES
                if (_input == "GA") return "GAB"; //GABON
                if (_input == "GM") return "GMB"; //GAMBIA
                if (_input == "GE") return "GEO"; //GEORGIA
                if (_input == "DE") return "DEU"; //GERMANY
                if (_input == "GH") return "GHA"; //GHANA
                if (_input == "GI") return "GIB"; //GIBRALTAR
                if (_input == "GR") return "GRC"; //GREECE
                if (_input == "GL") return "GRL"; //GREENLAND
                if (_input == "GD") return "GRD"; //GRENADA
                if (_input == "GP") return "GLP"; //GUADELOUPE
                if (_input == "GU") return "GUM"; //GUAM
                if (_input == "GT") return "GTM"; //GUATEMALA
                if (_input == "GG") return "GGY"; //GUERNSEY
                if (_input == "GN") return "GIN"; //GUINEA
                if (_input == "GW") return "GNB"; //GUINEA-BISSAU
                if (_input == "GY") return "GUY"; //GUYANA
                if (_input == "HT") return "HTI"; //HAITI
                if (_input == "HM") return "HMD"; //HEARD AND MCDONALD ISLANDS
                if (_input == "HN") return "HND"; //HONDURAS
                if (_input == "HK") return "HKG"; //HONG KONG
                if (_input == "HU") return "HUN"; //HUNGARY
                if (_input == "IS") return "ISL"; //ICELAND
                if (_input == "IN") return "IND"; //INDIA
                if (_input == "ID") return "IDN"; //INDONESIA
                if (_input == "IR") return "IRN"; //IRAN
                if (_input == "IQ") return "IRQ"; //IRAQ
                if (_input == "IE") return "IRL"; //IRELAND
                if (_input == "IM") return "IMN"; //ISLE OF MAN
                if (_input == "IL") return "ISR"; //ISRAEL
                if (_input == "IT") return "ITA"; //ITALY
                if (_input == "JM") return "JAM"; //JAMAICA
                if (_input == "JP") return "JPN"; //JAPAN
                if (_input == "JE") return "JEY"; //JERSEY (CHANNEL ISLANDS)
                if (_input == "JO") return "JOR"; //JORDAN
                if (_input == "KZ") return "KAZ"; //KAZAKHSTAN
                if (_input == "KE") return "KEN"; //KENYA
                if (_input == "KI") return "KIR"; //KIRIBATI
                if (_input == "KP") return "PRK"; //DEMOC.PEOPLES REP.OF KOREA
                if (_input == "KW") return "KWT"; //KUWAIT
                if (_input == "KG") return "KGZ"; //KYRGYZSTAN
                if (_input == "LA") return "LAO"; //LAOS
                if (_input == "LV") return "LVA"; //LATVIA
                if (_input == "LB") return "LBN"; //LEBANON
                if (_input == "LS") return "LSO"; //LESOTHO
                if (_input == "LR") return "LBR"; //LIBERIA
                if (_input == "LY") return "LBY"; //LIBYAN ARAB JAMAHIRIYA
                if (_input == "LI") return "LIE"; //LIECHTENSTEIN
                if (_input == "LT") return "LTU"; //LITHUANIA
                if (_input == "LU") return "LUX"; //LUXEMBOURG
                if (_input == "MO") return "MAC"; //MACAO
                if (_input == "MK") return "MKD"; //MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF
                if (_input == "MG") return "MDG"; //MADAGASCAR
                if (_input == "MW") return "MWI"; //MALAWI
                if (_input == "MY") return "MYS"; //MALAYSIA
                if (_input == "MV") return "MDV"; //MALDIVES
                if (_input == "ML") return "MLI"; //MALI
                if (_input == "MT") return "MLT"; //MALTA
                if (_input == "MH") return "MHL"; //MARSHALL ISLANDS
                if (_input == "MQ") return "MTQ"; //MARTINIQUE
                if (_input == "MR") return "MRT"; //MAURITANIA
                if (_input == "MU") return "MUS"; //MAURITIUS
                if (_input == "YT") return "MYT"; //MAYOTTE
                if (_input == "MX") return "MEX"; //MEXICO
                if (_input == "FM") return "FSM"; //MICRONESIA, FEDERATED STATES OF
                if (_input == "MD") return "MDA"; //MOLDOVA, REPUBLIC OF
                if (_input == "MC") return "MCO"; //MONACO
                if (_input == "MN") return "MNG"; //MONGOLIA
                if (_input == "ME") return "MNE"; //MONTENEGRO
                if (_input == "MS") return "MSR"; //MONTSERRAT
                if (_input == "MA") return "MAR"; //MOROCCO
                if (_input == "MZ") return "MOZ"; //MOZAMBIQUE
                if (_input == "MM") return "MMR"; //MYANMAR
                if (_input == "NA") return "NAM"; //NAMIBIA
                if (_input == "NR") return "NRU"; //NAURU
                if (_input == "NP") return "NPL"; //NEPAL
                if (_input == "NL") return "NLD"; //NETHERLANDS
                if (_input == "NC") return "NCL"; //NEW CALEDONIA
                if (_input == "NZ") return "NZL"; //NEW ZEALAND
                if (_input == "NI") return "NIC"; //NICARAGUA
                if (_input == "NE") return "NER"; //NIGER
                if (_input == "NG") return "NGA"; //NIGERIA
                if (_input == "NU") return "NIU"; //NIUE
                if (_input == "NF") return "NFK"; //NORFOLK ISLAND
                if (_input == "MP") return "MNP"; //NORTHERN MARIANA ISLANDS
                if (_input == "NO") return "NOR"; //NORWAY
                if (_input == "OM") return "OMN"; //OMAN
                if (_input == "PK") return "PAK"; //PAKISTAN
                if (_input == "PW") return "PLW"; //PALAU
                if (_input == "PS") return "PSE"; //PALESTINIAN TERRITORY, OCCUPIED
                if (_input == "PA") return "PAN"; //PANAMA
                if (_input == "PG") return "PNG"; //PAPUA NEW GUINEA
                if (_input == "PY") return "PRY"; //PARAGUAY
                if (_input == "PE") return "PER"; //PERU
                if (_input == "PH") return "PHL"; //PHILIPPINES
                if (_input == "PN") return "PCN"; //PITCAIRN
                if (_input == "PL") return "POL"; //POLAND
                if (_input == "PT") return "PRT"; //PORTUGAL
                if (_input == "PR") return "PRI"; //PUERTO RICO
                if (_input == "QA") return "QAT"; //QATAR
                if (_input == "RE") return "REU"; //REUNION
                if (_input == "RO") return "ROU"; //ROMANIA
                if (_input == "RU") return "RUS"; //RUSSIAN FEDERATION (USSR)
                if (_input == "RW") return "RWA"; //RWANDA
                if (_input == "BL") return "BLM"; //SAINT BARTHELEMY
                if (_input == "LC") return "LCA"; //SAINT LUCIA
                if (_input == "MF") return "MAF"; //SAINT MARTIN (FRENCH PART)
                if (_input == "VC") return "VCT"; //SAINT VINCENT AND GRENADINES
                if (_input == "WS") return "WSM"; //SAMOA
                if (_input == "SM") return "SMR"; //SAN MARINO
                if (_input == "ST") return "STP"; //SAO TOME AND PRINCIPE
                if (_input == "SA") return "SAU"; //SAUDI ARABIA
                if (_input == "SN") return "SEN"; //SENEGAL
                if (_input == "RS") return "SRB"; //SERBIA & MONTENEGRO
                if (_input == "SC") return "SYC"; //SEYCHELLES
                if (_input == "SL") return "SLE"; //SIERRA LEONE
                if (_input == "SG") return "SGP"; //SINGAPORE
                if (_input == "SX") return "SXM"; //SINT MAARTEN (DUTCH PART)
                if (_input == "SK") return "SVK"; //SLOVAK REPUBLIC
                if (_input == "SI") return "SVN"; //SLOVENIA
                if (_input == "SB") return "SLB"; //SOLOMON ISLANDS
                if (_input == "SO") return "SOM"; //SOMALIA
                if (_input == "ZA") return "ZAF"; //SOUTH AFRICA
                if (_input == "GS") return "SGS"; //SOUTH GEORGIA AND THE SOUTH SANDWICH ISLAND
                if (_input == "SS") return "SSD"; //SOUTH SUDAN
                if (_input == "ES") return "ESP"; //SPAIN
                if (_input == "LK") return "LKA"; //SRI LANKA
                if (_input == "SD") return "SDN"; //SUDAN
                if (_input == "SR") return "SUR"; //SURINAME
                if (_input == "SJ") return "SJM"; //SVALBARD AND JAN MAYEN ISLANDS
                if (_input == "SE") return "SWE"; //SWEDEN
                if (_input == "CH") return "CHE"; //SWITZERLAND
                if (_input == "SY") return "SYR"; //SYRIAN ARAB REPUBLIC
                if (_input == "TW") return "TWN"; //TAIWAN
                if (_input == "TJ") return "TJK"; //TAJIKISTAN
                if (_input == "TZ") return "TZA"; //TANZANIA UNITED REPUBLIC
                if (_input == "TH") return "THA"; //THAILAND
                if (_input == "KR") return "KOR"; //THE REPUBLIC OF KOREA
                if (_input == "TL") return "TLS"; //TIMOR-LESTE
                if (_input == "TG") return "TGO"; //TOGO
                if (_input == "TK") return "TKL"; //TOKELAU
                if (_input == "TO") return "TON"; //TONGA
                if (_input == "TT") return "TTO"; //TRINIDAD AND TOBAGO
                if (_input == "TN") return "TUN"; //TUNISIA
                if (_input == "TR") return "TUR"; //TURKIYE
                if (_input == "TM") return "TKM"; //TURKMENISTAN
                if (_input == "TC") return "TCA"; //TURKS AND CAICOS ISLANDS
                if (_input == "TV") return "TUV"; //TUVALU
                if (_input == "UG") return "UGA"; //UGANDA
                if (_input == "UA") return "UKR"; //UKRAINE
                if (_input == "AE") return "ARE"; //UNITED ARAB EMIRATES
                if (_input == "GB") return "GBR"; //UNITED KINGDOM
                if (_input == "US") return "USA"; //UNITED STATES OF AMERICA
                if (_input == "UM") return "UMI"; //UNITED STATES MINOR OUTLYING ISLANDS
                if (_input == "UY") return "URY"; //URUGUAY
                if (_input == "UZ") return "UZB"; //UZBEKISTAN
                if (_input == "VU") return "VUT"; //VANUATU
                if (_input == "VA") return "VAT"; //VATICAN CITY STATE (HOLY SEE)
                if (_input == "VE") return "VEN"; //VENEZUELA
                if (_input == "VN") return "VNM"; //VIETNAM
                if (_input == "VG") return "VGB"; //VIRGIN ISLANDS(BRITISH)
                if (_input == "VI") return "VIR"; //VIRGIN ISLANDS(US)
                if (_input == "WF") return "WLF"; //WALLIS AND FUTUNA ISLANDS
                if (_input == "EH") return "ESH"; //WESTERN SAHARA
                if (_input == "YE") return "YEM"; //YEMEN
                if (_input == "ZM") return "ZMB"; //ZAMBIA
                if (_input == "ZW") return "ZWE"; //ZIMBABWE
            }

            return "";
        },

        /**
         * Map state to IRBM state code.
         * @param {string} input - Input string.
         * @returns {string}
         */
        _generateIRBMStateCode: function (input)
        {
            // 00 All States
            // 01 Johor
            // 02 Kedah
            // 03 Kelantan
            // 04 Melaka
            // 05 Negeri Sembilan
            // 06 Pahang
            // 07 Pulau Pinang
            // 08 Perak
            // 09 Perlis
            // 10 Selangor
            // 11 Terengganu
            // 12 Sabah
            // 13 Sarawak
            // 14 Wilayah Persekutuan Kuala Lumpur
            // 15 Wilayah Persekutuan Labuan
            // 16 Wilayah Persekutuan Putrajaya
            // 17 Not Applicable

            let that = this;

            if (typeof input == "string")
            {
                let _input = input.toLowerCase().trim();

                if (_input == "not applicable" || that._isNullOrWhitespace(_input)) return "17";
                if (_input.includes("johor")) return "01";
                if (_input.includes("kedah")) return "02";
                if (_input.includes("kelantan")) return "03";
                if (_input.includes("melaka")) return "04";
                if (_input.includes("sembilan")) return "05";
                if (_input.includes("pahang")) return "06";
                if (_input.includes("pinang")) return "07";
                if (_input.includes("penang")) return "07";
                if (_input.includes("perak")) return "08";
                if (_input.includes("perlis")) return "09";
                if (_input.includes("selangor")) return "10";
                if (_input.includes("terengganu")) return "11";
                if (_input.includes("sabah")) return "12";
                if (_input.includes("sarawak")) return "13";
                if (_input.includes("lumpur")) return "14";
                if (_input.includes("labuan")) return "15";
                if (_input.includes("putrajaya")) return "16";
            }

            return "17"; //Not Applicable
        },

        /**
         * Map Quarto currency code to IRBM currency code. For now only support limited list of currency, will add as required.
         * @param {string} input - Input string.
         * @returns {string}
         */
        _generateIRBMCurrencyCode: function (input)
        {
            if (typeof input == "string")
            {
                let _input = input.toUpperCase().trim();

                if (_input == "AFN") return "AFA"; //AFGHANI	AFGHANISTAN
                if (_input == "ALL") return "ALL"; //LEK	ALBANIA
                if (_input == "DZD") return "DZD"; //ALGERIAN DINAR	ALGERIA
                if (_input == "ARS") return "ARS"; //PESO	ARGENTINA
                if (_input == "AMD") return "AMD"; //ARMENIAN DRAM	ARMENIA
                if (_input == "AWG") return "AWG"; //ARUBAN GUILDER	ARUBA
                if (_input == "AUD") return "AUD"; //AUSTRALIAN DOLLAR	AUSTRALIA
                if (_input == "AUD") return "AUD"; //AUSTRALIAN DOLLAR	COCOS ISLAND
                if (_input == "AUD") return "AUD"; //AUSTRALIAN DOLLAR	CHRISTMAS ISLANDS
                if (_input == "AUD") return "AUD"; //AUSTRALIAN DOLLAR	HEARD AND MCDONALD ISLANDS
                if (_input == "AUD") return "AUD"; //AUSTRALIAN DOLLAR	KIRIBATI
                if (_input == "AUD") return "AUD"; //AUSTRALIAN DOLLAR	NORFOLK ISLAND
                if (_input == "AUD") return "AUD"; //AUSTRALIAN DOLLAR	NAURU
                if (_input == "AUD") return "AUD"; //AUSTRALIAN DOLLAR	TUVALU
                if (_input == "BSD") return "BSD"; //BAHAMIAN DOLLAR 	BAHAMAS
                if (_input == "BDT") return "BDT"; //TAKA	BANGLADESH
                if (_input == "BBD") return "BBD"; //BARBADOS DOLLAR 	BARBADOS
                if (_input == "BZD") return "BZD"; //BELIZE DOLLAR	BELIZE
                if (_input == "BMD") return "BMD"; //BERMUDIAN DOLLAR	BERMUDA
                if (_input == "BTN") return "BTN"; //NGULTRUM	BHUTAN
                if (_input == "BOB") return "BOB"; //BOLIVIANO	BOLIVIA
                if (_input == "BAM") return "BAM"; //BOSNIA HERZEGOVINA MARKA	BOSNIA AND HERZEGOVINA
                if (_input == "BWP") return "BWP"; //PULA	BOTSWANA
                if (_input == "BND") return "BND"; //BRUNEI DOLLAR	BRUNEI DARUSSALAM
                if (_input == "BIF") return "BIF"; //BURUNDI FRANCE	BURUNDI
                if (_input == "CAD") return "CAD"; //CANADIAN DOLLAR	CANADA
                if (_input == "CVE") return "CVE"; //CAPE VERDE ESCUDO	CAPE VERDE
                if (_input == "KYD") return "KYD"; //CAYMAN ISLANDS DOLLAR	CAYMAN ISLANDS
                if (_input == "CLP") return "CLP"; //CHILEAN PESO	CHILE
                if (_input == "KMF") return "KMF"; //COMOROS FRANC	COMOROS
                if (_input == "CRC") return "CRC"; //COSTA RICAN COLON	COSTA RICA
                if (_input == "HRK") return "HRK"; //CROATIAN KUNA	CROATIA
                if (_input == "CUP") return "CUP"; //CUBAN PESO	CUBA
                if (_input == "CZK") return "CZK"; //KORUNA	CZECH REPUBLIC
                if (_input == "DKK") return "DKK"; //DANISH KRONE 	FAEROE ISLANDS
                if (_input == "DKK") return "DKK"; //DANISH KRONE	GREENLAND
                if (_input == "DJF") return "DJF"; //DJIBOUTI FRANC	DJIBOUTI
                if (_input == "DOP") return "DOP"; //DOMINICAN PESO	DOMINICAN REPUBLIC
                if (_input == "EGP") return "EGP"; //EGYPTIAN POUND	EGYPT
                if (_input == "SVC") return "SVC"; //EL SAVADOR COLON	EL SALVADOR
                if (_input == "ETB") return "ETB"; //ETHIOPIAN BIRR	ETHIOPIA
                if (_input == "EUR") return "EUR"; //EURO	AUSTRIA
                if (_input == "EUR") return "EUR"; //EURO	BELGIUM
                if (_input == "EUR") return "EUR"; //EURO	CURACAO
                if (_input == "EUR") return "EUR"; //EURO	GERMANY
                if (_input == "EUR") return "EUR"; //EURO	DENMARK
                if (_input == "EUR") return "EUR"; //EURO	FRANCE
                if (_input == "EUR") return "EUR"; //EURO	PORTUGAL
                if (_input == "EUR") return "EUR"; //EURO	KOSOVO
                if (_input == "EUR") return "EUR"; //EURO	SLOVENIA
                if (_input == "EUR") return "EUR"; //EURO	GEORGIA
                if (_input == "EUR") return "EUR"; //EURO	LITHUANIA
                if (_input == "EUR") return "EUR"; //EURO	MONTENEGRO
                if (_input == "EUR") return "EUR"; //EURO	MAYOTTE
                if (_input == "FKP") return "FKP"; //FALKLAND ISLANDS POUND	FALKLAND ISLANDS (MALVINAS)
                if (_input == "FJD") return "FJD"; //FIJI DOLLAR	FIJI
                if (_input == "GMD") return "GMD"; //DALASI	GAMBIA
                if (_input == "GIP") return "GIP"; //GIBRALTAR POUND	GIBRALTAR
                if (_input == "GTQ") return "GTQ"; //QUETZALNC BEAC	GUATEMALA
                if (_input == "GNF") return "GNF"; //GUINEA FRANC	GUINEA
                if (_input == "GYD") return "GYD"; //GUYANA DOLLAR	GUYANA
                if (_input == "HTG") return "HTG"; //GURDE 	HAITI
                if (_input == "HNL") return "HNL"; //LEMPIRA	HONDURAS
                if (_input == "HKD") return "HKD"; //HONGKONG DOLLAR	HONG KONG
                if (_input == "HUF") return "HUF"; //FORINT	HUNGARY
                if (_input == "ISK") return "ISK"; //ICELAND KRONA	ICELAND
                if (_input == "INR") return "INR"; //INDIAN RUPEE	INDIA
                if (_input == "IRR") return "IRR"; //IRANIAN RIAL	IRAN
                if (_input == "IQD") return "IQD"; //IRAQI DINAR	IRAQ
                if (_input == "ILS") return "ILS"; //SHEKEL	ISRAEL
                if (_input == "JMD") return "JMD"; //JAMAICAN DOLLAR	JAMAICA
                if (_input == "JPY") return "JPY"; //JAPANESE YEN	JAPAN
                if (_input == "JOD") return "JOD"; //JORDIAN DINAR	JORDAN
                if (_input == "KZT") return "KZT"; //TENGE	KAZAKHSTAN
                if (_input == "KES") return "KES"; //KENYAN SHILLING	KENYA
                if (_input == "KPW") return "KPW"; //NORTH KOREAN WON	DEMOC.PEOPLES REP.OF KOREA
                if (_input == "KRW") return "KRW"; //KOREAN WON	THE REPUBLIC OF KOREA
                if (_input == "KWD") return "KWD"; //KUWAITI DINAR 	KUWAIT
                if (_input == "KGS") return "KGS"; //SOMS	KYRGYZSTAN
                if (_input == "LAK") return "LAK"; //KIP	LAOS
                if (_input == "LBP") return "LBP"; //LEBANESE POUND 	LEBANON
                if (_input == "LSL") return "LSL"; //LOTI	LESOTHO
                if (_input == "LRD") return "LRD"; //LIBERIAN DOLLAR	LIBERIA
                if (_input == "LYD") return "LYD"; //LIBYAN DOLLAR	LIBYAN ARAB JAMAHIRIYA
                if (_input == "MOP") return "MOP"; //PATACA	MACAO
                if (_input == "MKD") return "MKD"; //DENAR	MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF
                if (_input == "MWK") return "MWK"; //KWACHA	MALAWI
                if (_input == "MVR") return "MVR"; //RUFIYAA	MALDIVES
                if (_input == "MRO") return "MRO"; //OUGUIYA	MAURITANIA
                if (_input == "MUR") return "MUR"; //MAURITIUS RUPEE	MAURITIUS
                if (_input == "MXN") return "MXN"; //MEXICAN PESO	MEXICO
                if (_input == "MDL") return "MDL"; //LEU MOLDOVA	MOLDOVA, REPUBLIC OF
                if (_input == "MNT") return "MNT"; //TUGRIK	MONGOLIA
                if (_input == "MAD") return "MAD"; //MOROCCAN DIRHAM	WESTERN SAHARA
                if (_input == "MAD") return "MAD"; //MOROCAN DIRHAM	MOROCCO
                if (_input == "MMK") return "MMK"; //MYANMAR KYAT	MYANMAR
                if (_input == "NAD") return "NAD"; //RAND	NAMIBIA
                if (_input == "NZD") return "NZD"; //NEW ZEALAND DOLLAR	COOK ISLANDS
                if (_input == "NZD") return "NZD"; //NEW ZEALAND DOLLAR	NIUE
                if (_input == "NZD") return "NZD"; //NEW ZEALAND DOLLAR	NEW ZEALAND
                if (_input == "NZD") return "NZD"; //NEW ZEALAND DOLLAR  	PITCAIRN
                if (_input == "NZD") return "NZD"; //NEW ZEALAND DOLLAR	TOKELAU
                if (_input == "NIO") return "NIO"; //CORDOBA	NICARAGUA
                if (_input == "NGN") return "NGN"; //NAIRA	NIGERIA
                if (_input == "NOK") return "NOK"; //NORWEGIAN KRONE	BOUVET ISLAND
                if (_input == "NOK") return "NOK"; //NORWEGIAN KRONE	NORWAY
                if (_input == "NOK") return "NOK"; //NORWEGIAN KRONE	SVALBARD AND JAN MAYEN ISLANDS
                if (_input == "OMR") return "OMR"; //RIAL OMANI	OMAN
                if (_input == "PKR") return "PKR"; //PAKISTAN RUPEE	PAKISTAN
                if (_input == "PAB") return "PAB"; //BALBOA	PANAMA
                if (_input == "PGK") return "PGK"; //KINA	PAPUA NEW GUINEA
                if (_input == "PYG") return "PYG"; //GUARANI	PARAGUAY
                if (_input == "PHP") return "PHP"; //PHILIPPINE PESO	PHILIPPINES
                if (_input == "PLN") return "PLN"; //ZLOTY	POLAND
                if (_input == "QAR") return "QAR"; //QATARI RIYAL	QATAR
                if (_input == "MYR") return "MYR"; //MALAYSIAN RINGGIT	MALAYSIA
                if (_input == "IDR") return "IDR"; //INDONESIAN RUPIAH 	INDONESIA
                if (_input == "RUB") return "RUB"; //RUSSIAN RUBLE 	RUSSIAN FEDERATION (USSR)
                if (_input == "RWF") return "RWF"; //RWANDA FRANC	RWANDA
                if (_input == "SHP") return "SHP"; //ST. HELENA POUND	ST. HELENA
                if (_input == "WST") return "WST"; //TALA	SAMOA
                if (_input == "STD") return "STD"; //DOBRA	SAO TOME AND PRINCIPE
                if (_input == "SAR") return "SAR"; //SAUDI ARABIA RIYAL	SAUDI ARABIA
                if (_input == "RSD") return "RSD"; //SERBIAN DINAR 	SERBIA & MONTENEGRO
                if (_input == "SLL") return "SLL"; //LEONE	SIERRA LEONE
                if (_input == "SGD") return "SGD"; //SINGAPORE DOLLAR	SINGAPORE
                if (_input == "SBD") return "SBD"; //SOLOMON ISLANDS DOLLAR	SOLOMON ISLANDS
                if (_input == "SOS") return "SOS"; //SOMALI SHILLING DOLLAR 	SOMALIA
                if (_input == "ZAR") return "ZAR"; //RAND	SOUTH AFRICA
                if (_input == "LKR") return "LKR"; //SRI LANKA RUPEE	SRI LANKA
                if (_input == "SDG") return "SDG"; //SUDANESE POUND	SOUTH SUDAN
                if (_input == "SZL") return "SZL"; //LILANGENI	ESWATINI, KINGDOM OF (SWAZILAND)
                if (_input == "SEK") return "SEK"; //SWEDISH KRONE	SWEDEN
                if (_input == "CHF") return "CHF"; //SWISS FRANC	SWITZERLAND
                if (_input == "CHF") return "CHF"; //SWISS FRANC	LIECHTENSTEIN
                if (_input == "SYP") return "SYP"; //SYRIAN POUND 	SYRIAN ARAB REPUBLIC
                if (_input == "TZS") return "TZS"; //TANZANIAN SHILLING	TANZANIA UNITED REPUBLIC
                if (_input == "THB") return "THB"; //THAI BHAT	THAILAND
                if (_input == "TOP") return "TOP"; //PAANGA	TONGA
                if (_input == "TTD") return "TTD"; //TRINIDAD AND TOBAGO DOLLAR	TRINIDAD AND TOBAGO
                if (_input == "TND") return "TND"; //TUNISIAN DINAR	TUNISIA
                if (_input == "TRY") return "TRL"; //TURKISH LIRA	TURKIYE
                if (_input == "UGX") return "UGX"; //UGANDA SHILLING	UGANDA
                if (_input == "AED") return "AED"; //UAE DIRHAM	UNITED ARAB EMIRATES
                if (_input == "GBP") return "GBP"; //POUND STERLING	ALAND ISLANDS
                if (_input == "GBP") return "GBP"; //POUND STERLING	CONGO, THE DEMOCRATIC REPUBLIC
                if (_input == "GBP") return "GBP"; //STERLING  POUND 	UNITED KINGDOM
                if (_input == "GBP") return "GBP"; //POUND STERLING	GUERNSEY
                if (_input == "GBP") return "GBP"; //POUND STERLING	ISLE OF MAN
                if (_input == "GBP") return "GBP"; //POUND STERLING	JERSEY (CHANNEL ISLANDS)
                if (_input == "GBP") return "GBP"; //POUND STERLING	SOUTH GEORGIA AND THE SOUTH SANDWICH ISLAND
                if (_input == "USD") return "USD"; //US DOLLAR	AMERICAN SAMOA
                if (_input == "USD") return "USD"; //USD DOLLAR	BAHRAIN
                if (_input == "USD") return "USD"; //USD DOLLAR	BRAZIL
                if (_input == "USD") return "USD"; //US DOLLAR	CHINA
                if (_input == "USD") return "USD"; //USD DOLLAR	COLOMBIA
                if (_input == "USD") return "USD"; //US DOLLAR 	MICRONESIA, FEDERATED STATES OF
                if (_input == "USD") return "USD"; //UNITED STATES DOLLAR	UNITED STATES OF AMERICA
                if (_input == "USD") return "USD"; //US DOLLAR	VIRGIN ISLANDS(BRITISH)
                if (_input == "USD") return "USD"; //US DOLLAR	VIRGIN ISLANDS(US)
                if (_input == "USD") return "USD"; //US DOLLAR	VIETNAM
                if (_input == "USD") return "USD"; //US DOLLAR	PALAU
                if (_input == "USD") return "USD"; //US DOLLAR	PUERTO RICO
                if (_input == "USD") return "USD"; //US DOLLAR	TURKS AND CAICOS ISLANDS
                if (_input == "USD") return "USD"; //US DOLLAR	TIMOR-LESTE
                if (_input == "USD") return "USD"; //USD DOLLAR	TAIWAN
                if (_input == "USD") return "USD"; //US DOLLAR	UNITED STATES MINOR OUTLYING ISLANDS
                if (_input == "USD") return "USD"; //US DOLLAR	GUAM
                if (_input == "USD") return "USD"; //US DOLLAR	BRITISH INDIAN OCEAN TERRITORY
                if (_input == "USD") return "USD"; //USD DOLLAR	CAMBODIA
                if (_input == "USD") return "USD"; //US DOLLAR	MARSHALL ISLANDS
                if (_input == "USD") return "USD"; //US DOLLAR	NORTHERN MARIANA ISLANDS
                if (_input == "USD") return "USD"; //USD DOLLAR	NEPAL
                if (_input == "UYU") return "UYU"; //URUGUAYAN PESO	URUGUAY
                if (_input == "UZS") return "UZS"; //SUM	UZBEKISTAN
                if (_input == "VUV") return "VUV"; //VATU	VANUATU
                if (_input == "YER") return "YER"; //YEMENI RIAL	YEMEN
                if (_input == "ZMK") return "ZMK"; //KWACHA	ZAMBIA
                if (_input == "ZWD") return "ZWD"; //ZIMBABWE DOLLAR	ZIMBABWE
            }

            return "";
        },

        /**
         * Map Quarto UOM code to IRBM UOM code. For now only support limited list of currency, will add as required.
         * @param {string} input - Input string.
         * @returns {string}
         */
        _generateIRBMUOMCode: function (input)
        {
            if (typeof input == "string")
            {
                //remove all (s), (S), / and spaces
                let _input = input.toUpperCase().replaceAll("/", "").replaceAll(" ", "").replaceAll("(S)", "").replaceAll("(s)", "");

                if (_input == "PIECE") return "XPP";                //piece
                if (_input == "PIECES") return "XPP";               //piece
                if (_input == "PC") return "XPP";                   //piece
                if (_input == "PCS") return "XPP";                  //piece
                if (_input == "PIECEEACH") return "XPP";            //piece
                if (_input == "UNIT") return "XUN";                 //unit
                if (_input == "UNITS") return "XUN";                //unit
                if (_input == "ACTIVITYUNIT") return "XUN";         //unit
                if (_input == "ACTIVITYUNITS") return "XUN";        //unit
                if (_input == "PERFORMANCEUNIT") return "XUN";      //unit
                if (_input == "PERFORMANCEUNITS") return "XUN";     //unit
                if (_input == "LITER") return "LTR";                //litre
                if (_input == "LITERS") return "LTR";               //litre
                if (_input == "LITRE") return "LTR";                //litre
                if (_input == "LITRES") return "LTR";               //litre
                if (_input == "LTR") return "LTR";                  //litre
                if (_input == "HECTOLITRE") return "HLT";           //hectolitre
                if (_input == "HECTOLITRES") return "HLT";          //hectolitre
                if (_input == "HECTOLITER") return "HLT";           //hectolitre
                if (_input == "HECTOLITERS") return "HLT";          //hectolitre
                if (_input == "KILOGRAM") return "KGM";             //kilogram
                if (_input == "KG") return "KGM";                   //kilogram
                if (_input == "METRICTAN") return "TNE";            //metric ton
                if (_input == "METRICTANS") return "TNE";           //metric ton
                if (_input == "METRICTON") return "TNE";            //metric ton
                if (_input == "METRICTONS") return "TNE";           //metric ton
                if (_input == "METRICTONNE") return "TNE";          //metric ton
                if (_input == "METRICTONNES") return "TNE";         //metric ton
                if (_input == "METRICTONNNE") return "TNE";         //metric ton
                if (_input == "METRICTONNNES") return "TNE";        //metric ton
                if (_input == "MTON") return "TNE";                 //metric ton
                if (_input == "MTONS") return "TNE";                //metric ton
                if (_input == "MT") return "TNE";                   //metric ton
                if (_input == "MATRICTAN") return "TNE";            //metric ton
                if (_input == "TON") return "TNE";                  //metric ton (*)
                if (_input == "TONNE") return "TNE";                //metric ton (*)
                if (_input == "TONNES") return "TNE";               //metric ton (*)
                if (_input == "BAG") return "XBG";                  //bag
                if (_input == "BAGS") return "XBG";                 //bag
                if (_input == "BG") return "XBG";                   //bag
                if (_input == "HECTARE") return "H18";              //square hectometre
                if (_input == "HECTARES") return "H18";             //square hectometre
                if (_input == "HECTARAGE") return "H18";            //square hectometre
                if (_input == "HECTARAGES") return "H18";           //square hectometre
                if (_input == "SET") return "SET";                  //set
                if (_input == "SETS") return "SET";                 //set
                if (_input == "JOB") return "E51";                  //job
                if (_input == "JOBS") return "E51";                 //job
                if (_input == "TASK") return "E51";                 //job
                if (_input == "TASKS") return "E51";                //job
                if (_input == "SERVICE&REPAIR") return "E48";       //service unit
                if (_input == "SERVICE") return "E48";              //service unit
                if (_input == "SERVICES") return "E48";             //service unit
                if (_input == "BOTTLE") return "XBO";               //bottle
                if (_input == "BOTTLES") return "XBO";              //bottle
                if (_input == "BOTT") return "XBO";                 //bottle
                if (_input == "BTL") return "XBO";                  //bottle
                if (_input == "PKT") return "XPA";                  //packet
                if (_input == "PAK") return "XPA";                  //packet
                if (_input == "PACK") return "XPA";                 //packet
                if (_input == "PACKS") return "XPA";                //packet
                if (_input == "PACKET") return "XPA";               //packet
                if (_input == "PACKETS") return "XPA";              //packet
                if (_input == "BUNGKUS") return "XPA";              //packet
                if (_input == "PACKCTN") return "XPA";              //packet
                if (_input == "PEK") return "XPA";                  //packet
                if (_input == "TIN") return "XTN";                  //tin
                if (_input == "TINS") return "XTN";                 //tin
                if (_input == "CAN") return "XTN";                  //tin
                if (_input == "CANS") return "XTN";                 //tin
                if (_input == "GRAM") return "GRM";                 //gram
                if (_input == "PAIR") return "PR";                  //pair
                if (_input == "PAIRS") return "PR";                 //pair
                if (_input == "PASANG") return "PR";                //pair
                if (_input == "PR") return "PR";                    //pair
                if (_input == "HOUR") return "HUR";                 //hour
                if (_input == "HOURS") return "HUR";                //hour
                if (_input == "HRS") return "HUR";                  //hour
                if (_input == "ROLL") return "XRO";                 //roll
                if (_input == "ROLLS") return "XRO";                //roll
                if (_input == "BOX") return "XBX";                  //box
                if (_input == "BOXES") return "XBX";                //box
                if (_input == "SMALLBOX") return "XBX";             //box
                if (_input == "SMALLBOXES") return "XBX";           //box
                if (_input == "TRIP") return "E54";                 //trip
                if (_input == "TRIPS") return "E54";                //trip
                if (_input == "LENGTH") return "LN";                //length
                if (_input == "LGTH") return "LN";                  //length
                if (_input == "EACH") return "EA";                  //each
                if (_input == "CYLINDER") return "XCY";             //cylinder
                if (_input == "CYLS") return "XCY";                 //cylinder
                if (_input == "CYL") return "XCY";                  //cylinder
                if (_input == "FEET") return "LF";                  //linear foot
                if (_input == "FOOT") return "LF";                  //linear foot
                if (_input == "LINEARFOOT") return "LF";            //linear foot
                if (_input == "RUNNINGFEET") return "LF";           //linear foot
                if (_input == "FEETRUN") return "LF";               //linear foot
                if (_input == "KAKI") return "LF";                  //linear foot
                if (_input == "MANDAY") return "E49";               //working day
                if (_input == "MANDAYS") return "E49";              //working day
                if (_input == "DAY") return "DAY";                  //day
                if (_input == "DAYS") return "DAY";                 //day
                if (_input == "CHAIN") return "M49";                //chain
                if (_input == "SHEET") return "XST";                //sheet
                if (_input == "SHEETS") return "XST";               //sheet
                if (_input == "PAPAN") return "XST";                //sheet
                if (_input == "PAIL") return "XPL";                 //pail
                if (_input == "PAILS") return "XPL";                //pail
                if (_input == "BUCKET") return "XBJ";               //bucket
                if (_input == "BUCKETS") return "XBJ";              //bucket
                if (_input == "MTH") return "MON";                  //month
                if (_input == "MONTH") return "MON";                //month
                if (_input == "MONTHLY") return "MON";              //month
                if (_input == "METER") return "MTR";                //metre
                if (_input == "METERS") return "MTR";               //metre
                if (_input == "METRE") return "MTR";                //metre
                if (_input == "METRES") return "MTR";               //metre
                if (_input == "MTR") return "MTR";                  //metre
                if (_input == "MTRS") return "MTR";                 //metre
                if (_input == "ASSEMBLY") return "AY";              //assembly
                if (_input == "BUNCH") return "XBH";                //bunch
                if (_input == "BUNCHES") return "XBH";              //bunch
                if (_input == "LOT") return "XLT";                  //lot
                if (_input == "PERSON") return "IE";                //person
                if (_input == "WORKER") return "IE";                //person
                if (_input == "USER") return "IE";                  //person
                if (_input == "HEAD") return "HEA";                 //head
                if (_input == "JAR") return "XJR";                  //jar
                if (_input == "KILOGRAMSECOND") return "KGS";       //kilogram per second
                if (_input == "KGS") return "KGS";                  //kilogram per second
                if (_input == "MILLILITRE") return "MLT";           //millilitre
                if (_input == "MILLILITRES") return "MLT";          //millilitre
                if (_input == "MILLILITER") return "MLT";           //millilitre
                if (_input == "MILLILITERS") return "MLT";          //millilitre
                if (_input == "ML") return "MLT";                   //millilitre
                if (_input == "MILILITRE") return "MLT";            //millilitre
                if (_input == "MILILITRES") return "MLT";           //millilitre
                if (_input == "MILILITER") return "MLT";            //millilitre
                if (_input == "MILILITERS") return "MLT";           //millilitre
                if (_input == "TUBE") return "XTU";                 //tube
                if (_input == "TUBES") return "XTU";                //tube
                if (_input == "DRUM") return "XDR";                 //drum
                if (_input == "DRUMS") return "XDR";                //drum
                if (_input == "VIAL") return "XDR";                 //vial
                if (_input == "VIALS") return "XDR";                //vial
                if (_input == "VAIL") return "XDR";                 //vial
                if (_input == "ACRE") return "ACR";                 //acre
                if (_input == "ACRES") return "ACR";                //acre
                if (_input == "REAM") return "RM";                  //ream
                if (_input == "RM") return "RM";                    //ream
                if (_input == "CARTON") return "XCT";               //carton
                if (_input == "CUBICMETER") return "MTQ";           //cubic metre
                if (_input == "CUBICMETERS") return "MTQ";          //cubic metre
                if (_input == "CUBICMETRE") return "MTQ";           //cubic metre
                if (_input == "CUBICMETRES") return "MTQ";          //cubic metre
                if (_input == "METERCUBE") return "MTQ";            //cubic metre
                if (_input == "M3") return "MTQ";                   //cubic metre
                if (_input == "BOOK") return "D63";                 //book
                if (_input == "BOOKS") return "D63";                //book
                if (_input == "STRIP") return "SR";                 //strip
                if (_input == "STRIPS") return "SR";                //strip
                if (_input == "KILOMETRE") return "KMT";            //kilometre
                if (_input == "KILOMETRES") return "KMT";           //kilometre
                if (_input == "KILOMETER") return "KMT";            //kilometre
                if (_input == "KILOMETERS") return "KMT";           //kilometre
                if (_input == "KWH") return "KWH";                  //kilowatt hour
                if (_input == "KILOWATTHOUR") return "KWH";         //kilowatt hour
                if (_input == "KILOWATTHOURS") return "KWH";        //kilowatt hour
                if (_input == "KILOWATT-HOUR") return "KWH";        //kilowatt hour
                if (_input == "DOZEN") return "DZN";                //dozen
                if (_input == "COIL") return "XCL";                 //coil
                if (_input == "GALLON") return "GLI";               //gallon (UK)
                if (_input == "GALLONS") return "GLI";              //gallon (UK)
                if (_input == "GAL") return "GLI";                  //gallon (UK)
                if (_input == "GALL") return "GLI";                 //gallon (UK)
                if (_input == "GLN") return "GLI";                  //gallon (UK)
                if (_input == "CAPSULE") return "XAV";              //capsule
                if (_input == "CAPSULES") return "XAV";             //capsule
                if (_input == "SACHET") return "XSH";               //sachet
                if (_input == "SACTHET") return "XSH";              //sachet
                if (_input == "BLOCK") return "XOK";                //block
                if (_input == "PAD") return "PD";                   //pad
                if (_input == "PADS") return "PD";                  //pad
                if (_input == "BDLE") return "XBE";                 //bundle
                if (_input == "BUNDLE") return "XBE";               //bundle
                if (_input == "BUNDLES") return "XBE";              //bundle
                if (_input == "ROUND") return "D65";                //round
                if (_input == "TAB") return "U2";                   //tablet
                if (_input == "TABLET") return "U2";                //tablet
                if (_input == "CONTAINER") return "XCN";            //container
                if (_input == "BALE") return "XBL";                 //bale
                if (_input == "CASE") return "XCS";                 //case
                if (_input == "INCH") return "INH";                 //inch
                if (_input == "INCHES") return "INH";               //inch
                if (_input == "YR") return "ANN";                   //year
                if (_input == "YEAR") return "ANN";                 //year
                if (_input == "YEARS") return "ANN";                //year
                if (_input == "BATCH") return "5B";                 //batch
                if (_input == "BATCHES") return "5B";               //batch
                if (_input == "PALLET") return "XPX";               //pallet
                if (_input == "KIT") return "KT";                   //kit
                if (_input == "CUBICYARD") return "YDQ";            //cubic yard
                if (_input == "CUBICYATT") return "YDQ";            //cubic yard
                if (_input == "CYRD") return "YDQ";                 //cubic yard
                if (_input == "SQFT") return "FTK";                 //square foot
                if (_input == "SQUAREFEET") return "FTK";           //square foot
                if (_input == "SQUAREFOOT") return "FTK";           //square foot
                if (_input == "CUBICFOOT") return "FTQ";            //cubic foot
                if (_input == "CUBICFEET") return "FTQ";            //cubic foot
                if (_input == "CBFT") return "FTQ";                 //cubic foot
                if (_input == "CUFT") return "FTQ";                 //cubic foot
                if (_input == "STICK") return "STC";                //stick
                if (_input == "BATANG") return "STC";               //stick
                if (_input == "TRAY") return "XPU";                 //tray
                if (_input == "GROSS") return "GRO";                //gross
                if (_input == "CARBOY") return "XCO";               //carboy
                if (_input == "PERCENT") return "P1";               //percent
                if (_input == "PERCENTACE") return "P1";            //percent
                if (_input == "PER") return "P1";                   //percent
                if (_input == "POT") return "XPT";                  //pot
                if (_input == "OUNCE") return "ONZ";                //ounce
                if (_input == "OZ") return "ONZ";                   //ounce
                if (_input == "BOARDFOOT") return "BFT";            //board foot
                if (_input == "BOARDFEET") return "BFT";            //board foot
                if (_input == "LUMSUM") return "LS";                //lump sum
                if (_input == "LUMPSUM") return "LS";               //lump sum
                if (_input == "SUM") return "LS";                   //lump sum
                if (_input == "AMPOULE") return "XAM";              //ampoule
                if (_input == "AMPULE") return "XAM";               //ampoule
                if (_input == "BAR") return "XBR";                  //bar
                if (_input == "KILOMETERHOUR") return "KMH";        //kilometre per hour
                if (_input == "KILOMETREHOUR") return "KMH";        //kilometre per hour
                if (_input == "METERSECOND") return "MTS";          //metre per second
                if (_input == "METRESECOND") return "MTS";          //metre per second
                if (_input == "METERSECONDSQUARED") return "MTS";   //metre per second squared
                if (_input == "METRESECONDSQUARED") return "MTS";   //metre per second squared
                if (_input == "MM") return "MMT";                   //millimetre
                if (_input == "MILIMETER") return "MMT";            //millimetre
                if (_input == "MILIMETERS") return "MMT";           //millimetre
                if (_input == "MILIMETRE") return "MMT";            //millimetre
                if (_input == "MILIMETRES") return "MMT";           //millimetre
                if (_input == "CM") return "CMT";                   //centimetre
                if (_input == "CENTIMETRE") return "CMT";           //centimetre
                if (_input == "CENTIMETRES") return "CMT";          //centimetre
                if (_input == "CENTIMETER") return "CMT";           //centimetre
                if (_input == "CENTIMETERS") return "CMT";          //centimetre
                if (_input == "CENTIMETERSECOND") return "2M";      //centimetre per second
                if (_input == "CENTIMETRESECOND") return "2M";      //centimetre per second
                if (_input == "DECIMETER") return "DMT";            //decimetre
                if (_input == "DECIMETERS") return "DMT";           //decimetre
                if (_input == "DECIMETRE") return "DMT";            //decimetre
                if (_input == "DECIMETRES") return "DMT";           //decimetre
                if (_input == "NANOMETER") return "C45";            //nanometre
                if (_input == "NANOMETERS") return "C45";           //nanometre
                if (_input == "NANOMETRE") return "C45";            //nanometre
                if (_input == "NANOMETRES") return "C45";           //nanometre
                if (_input == "SQUAREMETER") return "MTK";          //square metre
                if (_input == "SQUAREMETRE") return "MTK";          //square metre
                if (_input == "SQM2UAREMETER") return "MTK";        //square metre
                if (_input == "SQM2UAREMETRE") return "MTK";        //square metre
                if (_input == "SQUAREMTR") return "MTK";            //square metre
                if (_input == "SQUARECENTIMETER") return "CMK";     //square centimetre
                if (_input == "SQUARECENTIMETRE") return "CMK";     //square centimetre
                if (_input == "CUBICCENTIMETER") return "CMQ";      //cubic centimetre
                if (_input == "CUBICCENTIMETERS") return "CMQ";     //cubic centimetre
                if (_input == "CUBICCENTIMETRE") return "CMQ";      //cubic centimetre
                if (_input == "CUBICCENTIMETRES") return "CMQ";     //cubic centimetre
                if (_input == "CUBICDECIMETER") return "DMQ";       //cubic decimetre
                if (_input == "CUBICDECIMETERS") return "DMQ";      //cubic decimetre
                if (_input == "CUBICDECIMETRE") return "DMQ";       //cubic decimetre
                if (_input == "CUBICDECIMETRES") return "DMQ";      //cubic decimetre
                if (_input == "VEHICLE") return "XVN";              //vehicle
                if (_input == "BIN") return "XBI";                  //bin
                if (_input == "REEL") return "XRL";                 //reel
                if (_input == "ELA") return "YRD";                  //yard
                if (_input == "YARD") return "YRD";                 //yard
                if (_input == "YARD(ELA)") return "YRD";            //yard
                if (_input == "AMPERE") return "AMP";               //ampere
                if (_input == "MILLIAMPERE") return "4K";           //milliampere
                if (_input == "PICOSECOND") return "H70";           //picosecond
                if (_input == "POUND") return "LBR";                //pound
                if (_input == "POUNDS") return "LBR";               //pound
                if (_input == "IBS") return "LBR";                  //pound
                if (_input == "LBR") return "LBR";                  //pound
                if (_input == "CARD") return "XCM";                 //card
                if (_input == "PASCAL") return "PAL";               //pascal
                if (_input == "PASCALSECOND") return "C65";         //pascal second
                if (_input == "PINT") return "PTI";                 //pint (UK)
                if (_input == "PINTS") return "PTI";                //pint (UK)
                if (_input == "PIN") return "PTI";                  //pint (UK)
                if (_input == "LINK") return "LK";                  //link
                if (_input == "PARTSPERMILLION") return "59";       //part per million
                if (_input == "PAGE") return "ZP";                  //page
                if (_input == "KILOHERTZ") return "KHZ";            //kilohertz
                if (_input == "QUART") return "QTI";                //quart (UK)
                if (_input == "QUARTS") return "QTI";               //quart (UK)
                if (_input == "NEST") return "XNS";                 //nest
                if (_input == "SACK") return "XSA";                 //sack
                if (_input == "BARREL") return "XBA";               //barrel
                if (_input == "TONG") return "XBA";                 //barrel
                if (_input == "TANK") return "XTG";                 //tank
                if (_input == "CARTRIDGE") return "XCQ";            //cartridge
                if (_input == "BASKET") return "XBK";               //basket
                if (_input == "POLE") return "P53";                 //unit pole
                if (_input == "GLASS") return "XGR";                //glass
                if (_input == "CUP") return "XCU";                  //cup
                if (_input == "TUB") return "XTB";                  //tub
                if (_input == "CANISTER") return "XCI";             //canister
                if (_input == "DECALITRE") return "A44";            //decalitre
                if (_input == "AD") return "AD";                    //byte
                if (_input == "CASK") return "XCK";                 //cask
                if (_input == "PARCEL") return "XPC";               //parcel
                if (_input == "LOAD") return "NL";                  //load
                if (_input == "DN") return "DN";                    //decinewton metre
                if (_input == "KEG") return "XKG";                  //keg
                if (_input == "CRATE") return "XCR";                //crate
                if (_input == "GM") return "GM";                    //gram per square metre
            }

            return "XUN"; //unit
            //return "XNA"; //not available
        },

        /**
         * Generate digital signature for UBL formatted document.
         * @typedef {Object} container - Container for UBL formatted document in form of XML, JSON
         * @property {string} content - UBL formatted document in form of string.
         * @property {string} codeNumber - Document reference number used by Supplier for internal tracking purpose.
         * @property {string} format - XML or JSON, default to XML.
         *
         * @param {container[]} input - Document string.
         * @returns {Promise<container[]>}
         */
        _generateSignature: function (input)
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                $http({
                    url: $rootScope.financeWebApiUrl + 'EInvoice/SignInvoices',
                    method: "POST",
                    data: input,
                    retry: false
                }).then((result) =>
                {
                    resolve(result.data);
                }, () => reject());
            });
        },

        /**
         * Unauthorize current session.
         */
        _unAuth: function ()
        {
            //reset authorization properties
            let that = this;
            that._authObject.token = "";
            that._authObject.tokenGenerateTime = new Date(2000, 0, 1);
            that._authObject.tokenExpireInSecond = 0;
            that._authObject.clientTIN = "";
        },

        /**
         * Check if current session is authorized.
         * @param {string} clientTIN - Client TIN.
         * @returns {boolean}
         */
        _isAuth: function (clientTIN)
        {
            //check if it is Auth
            let that = this;
            let expectedExpiry = new Date(that._authObject.tokenGenerateTime);
            expectedExpiry.setSeconds(expectedExpiry.getSeconds() + that._authObject.tokenExpireInSecond - that._authObject.tokenExpireSafetyMarginInSecond);

            if (new Date(Date.now()) >= expectedExpiry)
            {
                return false;
            }
            else if (that._isNullOrWhitespace(that._authObject.token) || that._isNullOrWhitespace(that._authObject.clientTIN) || that._isNullOrWhitespace(clientTIN))
            {
                return false;
            }
            else if (that._authObject.clientTIN != clientTIN.trim())
            {
                return false;
            }
            else
            {
                return true;
            }
        },

        /**
         * Do exactly nothing.
         */
        _doNothing: function ()
        {
            prompt("Nothing");
        },

        /**
         * Request Authentication Token from IRBM using Client ID and Secret
         * @param {string} clientTIN - Client TIN.
         * @returns {Promise<void>}
         */
        _requestAuth: function (clientTIN)
        {
            //reset authorization properties
            let that = this;
            that._unAuth();

            //request and construct new authorization properties
            return new Promise((resolveAuth, rejectAuth) =>
            {
                that._unAuth();

                if (that._isNullOrWhitespace(clientTIN))
                {
                    rejectAuth("Invalid Input. Company TIN is missing.");
                }
                else
                {
                    $http({
                        url: $rootScope.financeWebApiUrl + 'EInvoice/RequestAuth?clientTIN=' + clientTIN.trim(),
                        method: "POST",
                        retry: false
                    }).then((result) =>
                    {
                        that._unAuth();
                        if (typeof result?.data?.access_token == "undefined"
                            || typeof result?.data?.expires_in == "undefined"
                            || typeof result?.data?.token_type == "undefined")
                        {
                            rejectAuth("Invalid response from IRBM server.");
                        }
                        else
                        {
                            //decode token to check for permission
                            let isPermissible = false;

                            //ourselves as first party
                            if (clientTIN.trim() == "C4898308100")
                            {
                                isPermissible = true;
                            }
                            else
                            {
                                try
                                {
                                    let decodedToken = that._decodeToken(result.data.token_type + " " + result.data.access_token);

                                    if (Array.isArray(decodedToken?.Permissions))
                                    {
                                        if (decodedToken.Permissions.includes("SubmitDoc")
                                            //&& decodedToken.Permissions.includes("ViewDoc") //view is always enabled
                                            && decodedToken.Permissions.includes("CancelDoc"))
                                        {
                                            isPermissible = true;
                                        }
                                    }
                                }
                                catch { isPermissible = false; }
                            }

                            if (isPermissible)
                            {
                                that._authObject.token = result.data.token_type + " " + result.data.access_token;
                                that._authObject.tokenGenerateTime = new Date(Date.now());
                                that._authObject.tokenExpireInSecond = +(result.data.expires_in);
                                //account for safety margin, shorten the time by 230s (Azure timeout)
                                that._authObject.tokenExpireInSecond = that._authObject.tokenExpireInSecond > 230 ? (that._authObject.tokenExpireInSecond - 230) : that._authObject.tokenExpireInSecond;
                                that._authObject.clientTIN = clientTIN.trim();
                                resolveAuth();
                            }
                            else
                            {
                                rejectAuth("Required permissions (View, Submit, Cancel) are not granted.");
                            }
                        }
                    }, (error) =>
                    {
                        that._unAuth();
                        if ((error?.data?.error) ?? "" == "invalid_client")
                        {
                            rejectAuth("Invalid Client ID and Client Secret.");
                        }
                        else
                        {
                            rejectAuth("Error encountered while connecting to IRBM server.");
                        }
                    });
                }
            });
        },

        /**
         * Validate TIN identity
         * @param {string} clientTIN - Client TIN.
         * @param {string} tin - The Tax Identification Number to get the validity of the tin.
         * @param {string} idType - (NRIC, Passport number, Business registration number, army number).
         * @param {string} idValue - The actual value of the ID Type selected.
         * @returns {Promise<void>}
         */
        _validateTIN: function (clientTIN, tin, idType, idValue)
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN) || that._isNullOrWhitespace(tin) || that._isNullOrWhitespace(idType) || that._isNullOrWhitespace(idValue))
                {
                    reject("Invalid Input.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/ValidateTIN?token=' + that._authObject.token + '&tin=' + tin + '&idType=' + idType + '&idValue=' + idValue + '',
                            method: "GET",
                            retry: false
                        }).then((result) =>
                        {
                            resolve();
                        }, (error) =>
                        {
                            reject();
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },

        /**
         * Submit list of documents to IRBM.
         * @typedef {Object} formatted_document - Document objects submitted. List should have at least one document. The document should follow the UBL 2.1 schema based on the document type version.
         * @property {string} format - XML, JSON.
         * @property {string} document - The base64 of the document JSON or XML.
         * @property {string} documentHash - The hash value of the document being submitted.
         * @property {string} codeNumber - Document reference number used by Supplier for internal tracking purpose.
         *
         * @typedef {Object} accepted_document - Accepted document.
         * @property {string} uuid - Unique document ID assigned by e-Invoice. 26 Latin alphanumeric symbols.
         * @property {string} invoiceCodeNumber - Document reference number used by Supplier for internal tracking purpose.
         *
         * @typedef {Object} rejected_document - Rejected document.
         * @property {string} invoiceCodeNumber - Document reference number used by Supplier for internal tracking purpose
         * @property {string} error - Error information detailing why the document was not accepted in this submission.
         *
         * @typedef {Object} result - Result of IRBM submission.
         * @property {string} submissionUID - Unique ID of the submission. 26 Latin alphanumeric symbols.
         * @property {accepted_document[]} acceptedDocuments - List of documents that are initially accepted (passed sync validations) together with their invoice code numbers and newly assigned IDs.
         * @property {rejected_document[]} rejectedDocuments - List of documents that are not accepted together with their invoice code numbers and error information.
         *
         * @param {string} clientTIN - Client TIN.
         * @param {formatted_document[]} documents - List of documents (formatted to IRBM acceptable format) to submit.
         * @returns {Promise<result>}
         */
        _submitDocument: function (clientTIN, documents)
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN) || !Array.isArray(documents) || documents.length == 0)
                {
                    reject("Invalid Input.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/SubmitDocument?token=' + that._authObject.token + '',
                            method: "POST",
                            retry: false,
                            data: JSON.stringify(JSON.stringify({ documents: documents }))
                        }).then((result) =>
                        {
                            if (typeof result?.data?.acceptedDocuments == "undefined"
                                || typeof result?.data?.rejectedDocuments == "undefined"
                                || typeof result?.data?.submissionUid == "undefined")
                            {
                                reject("Invalid response from IRBM server.");
                            }
                            else
                            {
                                resolve(result.data);
                            }
                        }, (error) =>
                        {
                            reject(error?.data);
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },

        /**
         * Validate document integrity
         * @typedef {Object} unformatted_document_detail
         * @property {number} TransDetKey - TransDetKey.
         * @property {number} RefTransDetKey - RefTransDetKey for Tax Line to refer to Base Line.
         * @property {string} RefDocNum - The Original Document Number which CN/DN adjust for. IE, if the CN is to adjust SI, then RefDocNum should be DocNum of SI.
         * @property {string} RefIRBMNum - The Original IRBM Document Number which CN/DN adjust for. IE, if the CN is to adjust SI, then RefIRBM should be IRBM of SI.
         * @property {string} Ind - Line Indicator.
         * @property {boolean} IsAdvance - To indicate this is advance payment line.
         * @property {Date} AdvanceInvoiceDate - The Original Document Invoice Date for advance payment.
         * @property {string} AdvanceDocNum - The Original Document Number for advance payment.
         * @property {number} OrigTransAmt - Line Transaction Original Amount.
         * @property {number} DiscountAmt - Line Transaction Original Discount Amount.
         * @property {string} Classification - IRBM's classification of product/service (Based on SYT_ClassCode).
         * @property {string} Remarks - Line Remarks.
         * @property {number} UnitPrice - Unit Price.
         * @property {number} TaxPer - Tax Percentage.
         * @property {string} TaxType - Tax Type (SALES/SERVICE).
         * @property {number} Qty - Quantity.
         * @property {string} UOM - UOM.
         *
         * @typedef {Object} unformatted_document - Document to be formatted into IRBM acceptable form.
         * @property {number} TransHdrKey - TransHdrKey.
         * @property {string} Source - Document Source.
         * @property {string} DocNum - Unique Document Number (Will prepend OUCode to ensure uniqueness).
         * @property {boolean} IsRefund - Indicate CN/DN is for Refund.
         * @property {string} CDType - Credit/Debit Note Type.
         * @property {Date} GLDate - Document Date.
         * @property {Date} InvoiceDate - Invoice Date.
         * @property {string} InvNum - Invoice Number.
         * @property {string} PayTermDesc - Payment Term (Need to map to IRBM version).
         * @property {number} DocAmt - Document Amount.
         * @property {string} CurrCode - Document Currency Code (Need to map to IRBM version).
         * @property {number} ExRateFunc - Exchange Rate.
         * @property {string} ConvertType - Convert Type (M for multiple, D for divide)
         * @property {string} OUCode - OU Code.
         * @property {string} SupplierName - Supplier's name (Supplier : Us / The one who is selling)
         * @property {string} SupplierTIN - Supplier's TIN (Supplier : Us / The one who is selling)
         * @property {string} SupplierNRIC - Supplier's NRIC, must provide either NRIC, BRNo, PassportNo or ArmyNo (Supplier : Us / The one who is selling)
         * @property {string} SupplierBRNo - Supplier's Business Registration No, must provide either NRIC, BRNo, PassportNo or ArmyNo (Supplier : Us / The one who is selling)
         * @property {string} SupplierPassportNo - Supplier's Passport No, must provide either NRIC, BRNo, PassportNo or ArmyNo (Supplier : Us / The one who is selling)
         * @property {string} SupplierArmyNo - Supplier's Army No, must provide either NRIC, BRNo, PassportNo or ArmyNo (Supplier : Us / The one who is selling)
         * @property {string} SupplierSST - Supplier's SST, optional but technically mandatory but we won't know if supplier is SST registered (Supplier : Us / The one who is selling)
         * @property {string} SupplierEmail - Supplier's Email, optional (Supplier : Us / The one who is selling)
         * @property {string} SupplierPhone - Supplier's Phone, optional (Supplier : Us / The one who is selling)
         * @property {string} SupplierAddressStreet - Supplier's Address' Street (Supplier : Us / The one who is selling)
         * @property {string} SupplierAddressPostCode - Supplier's Address' Post Code (Supplier : Us / The one who is selling)
         * @property {string} SupplierAddressCity - Supplier's Address' City (Supplier : Us / The one who is selling)
         * @property {string} SupplierAddressState  - Supplier's Address' State (Supplier : Us / The one who is selling)
         * @property {string} SupplierAddressCountry - Supplier's Address' Country (Supplier : Us / The one who is selling)
         * @property {string} SupplierMSIC - Supplier's MSIC (Based On SYT_MISCCode) (Supplier : Us / The one who is selling)
         * @property {string} SupplierMSICDescription - Supplier's MSIC Description (Based On SYT_MISCCode) (Supplier : Us / The one who is selling)
         * @property {string} BuyerName - Buyer's name (Buyer : Them / The one who is buying)
         * @property {string} BuyerTIN - Buyer's TIN (Buyer : Them / The one who is buying)
         * @property {string} BuyerNRIC - Buyer's NRIC, must provide either NRIC, BRNo, PassportNo or ArmyNo (Buyer : Them / The one who is buying)
         * @property {string} BuyerBRNo - Buyer's Business Registration No, must provide either NRIC, BRNo, PassportNo or ArmyNo (Buyer : Them / The one who is buying)
         * @property {string} BuyerPassportNo - Buyer's Passport No, must provide either NRIC, BRNo, PassportNo or ArmyNo (Buyer : Them / The one who is buying)
         * @property {string} BuyerArmyNo - Buyer's Army No, must provide either NRIC, BRNo, PassportNo or ArmyNo (Buyer : Them / The one who is buying)
         * @property {string} BuyerSST - Buyer's SST, optional but technically mandatory but we won't know if Buyer is SST registered (Buyer : Them / The one who is buying)
         * @property {string} BuyerEmail - Buyer's Email, optional (Buyer : Them / The one who is buying)
         * @property {string} BuyerPhone - Buyer's Phone, optional (Buyer : Them / The one who is buying)
         * @property {string} BuyerAddressStreet - Buyer's Address' Street (Buyer : Them / The one who is buying)
         * @property {string} BuyerAddressPostCode - Buyer's Address' Post Code (Buyer : Them / The one who is buying)
         * @property {string} BuyerAddressCity - Buyer's Address' City (Buyer : Them / The one who is buying)
         * @property {string} BuyerAddressState  - Buyer's Address' State (Buyer : Them / The one who is buying)
         * @property {string} BuyerAddressCountry - Buyer's Address' Country (Buyer : Them / The one who is buying)
         * @property {unformatted_document_detail[]} Details - Document Details.
         *
         * @param {unformatted_document} document - Document to be formatted into IRBM acceptable format.
         */
        _validateDocument: function (document)
        {
            let that = this;

            if ((typeof document == "undefined") || !Array.isArray(document.Details) || document.Details.length == 0)
            {
                throw "Validation Error : Missing Required Field (Detail).";
            }
            else if (!that._isNullOrWhitespace(document.CurrCode) && that._generateIRBMCurrencyCode(document.CurrCode) == "")
            {
                throw "Validation Error : Unsupported Document Currency.";
            }
            else if ((typeof document.TransHdrKey != "number") || document.TransHdrKey <= 0)
            {
                throw "Validation Error : Missing Required Field (TransHdrKey).";
            }
            else if (that._isNullOrWhitespace(document.Source))
            {
                throw "Validation Error : Missing Required Field (Source).";
            }
            else if ((document.Source == "CN" || document.Source == "DN") && that._isNullOrWhitespace(document.CDType))
            {
                throw "Validation Error : Missing Required Field (Credit/Debit Note Type).";
            }
            else if (["SI", "SIE", "SIC", "SB"].includes(document.Source) == false
                && (["CN", "DN"].includes(document.Source) && ["SAL", "PUR"].includes(document.CDType)) == false
            )
            {
                throw "Validation Error : Unsupported Document Type.";
            }
            else if (that._isNullOrWhitespace(document.DocNum))
            {
                throw "Validation Error : Missing Required Field (Document No.).";
            }
            else if (!(document.GLDate instanceof Date)) //TODO : Check TimeZone
            {
                throw "Validation Error : Missing Required Field (Journal Date).";
            }
            else if (that._isNullOrWhitespace(document.CurrCode))
            {
                throw "Validation Error : Missing Required Field (Document Currency).";
            }
            else if ((typeof document.ExRateFunc != "number") || document.ExRateFunc <= 0)
            {
                throw "Validation Error : Missing Required Field (Currency Exchange Rate).";
            }
            else if (that._isNullOrWhitespace(document.ConvertType))
            {
                throw "Validation Error : Missing Required Field (Currency Conversion Method).";
            }
            else if (!that._isNullOrWhitespace(document.ConvertType) && document.ConvertType != "M" && document.ConvertType != "D")
            {
                throw "Validation Error : Invalid Data (Currency Conversion Method).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.PayTermDesc)))
            {
                throw "Validation Error : Missing Required Field (Payment Term).";
            }
            else if (!(document.InvoiceDate instanceof Date)) //TODO : Check TimeZone
            {
                throw "Validation Error : Missing Required Field (Invoice Date).";
            }
            else if (that._isNullOrWhitespace(document.InvNum) && document.Source != "SB") //Self Bill do not have Invoice No
            {
                throw "Validation Error : Missing Required Field (Invoice No.).";
            }
            else if ((typeof document.DocAmt != "number"))
            {
                throw "Validation Error : Missing Required Field (Document Amount).";
            }
            else if (that._isNullOrWhitespace(document.OUCode))
            {
                throw "Validation Error : Missing Required Field (Operating Unit).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.BuyerName)))
            {
                throw "Validation Error : Missing Required Field (Customer's Name).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.BuyerTIN)))
            {
                throw "Validation Error : Missing Required Field (Customer's TIN).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.BuyerNRIC))
                && that._isNullOrWhitespace(that._sanitiseInput(document.BuyerBRNo))
                && that._isNullOrWhitespace(that._sanitiseInput(document.BuyerArmyNo))
                && that._isNullOrWhitespace(that._sanitiseInput(document.BuyerPassportNo)))
            {
                throw "Validation Error : Missing Required Field (Customer's Identification Info).";
            }
            else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerBRNo)) && that._sanitiseInput(document.BuyerBRNo).length > 20)
            {
                throw "Validation Error : Invalid Data (Customer's Business Registration No.).";
            }
            else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerNRIC)) && that._sanitiseInput(document.BuyerNRIC).length > 12)
            {
                throw "Validation Error : Invalid Data (Customer's NRIC No.).";
            }
            else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerArmyNo)) && that._sanitiseInput(document.BuyerArmyNo).length > 12)
            {
                throw "Validation Error : Invalid Data (Customer's Army No.).";
            }
            else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerPassportNo)) && that._sanitiseInput(document.BuyerPassportNo).length > 12)
            {
                throw "Validation Error : Invalid Data (Customer's Passport No.).";
            }
            else if (!that._isNullOrWhitespace(document.BuyerEmail) && !that._isValidRFC5321Email(document.BuyerEmail))
            {
                throw "Validation Error : Invalid Data (Customer's Email).";
            }
            else if (!that._isNullOrWhitespace(document.BuyerPhone) && !that._isValidE164PhoneNumber(document.BuyerPhone))
            {
                throw "Validation Error : Invalid Data (Customer's Phone).";
            }
            else if (that._isNullOrWhitespace(that._generateIRBMCountryCode(document.BuyerAddressCountry)))
            {
                throw "Validation Error : Missing Required Field (Customer's Country).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.SupplierName)))
            {
                throw "Validation Error : Missing Required Field (Supplier's Name).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.SupplierTIN)))
            {
                throw "Validation Error : Missing Required Field (Supplier's TIN).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.SupplierNRIC))
                && that._isNullOrWhitespace(that._sanitiseInput(document.SupplierBRNo))
                && that._isNullOrWhitespace(that._sanitiseInput(document.SupplierArmyNo))
                && that._isNullOrWhitespace(that._sanitiseInput(document.SupplierPassportNo)))
            {
                throw "Validation Error : Missing Required Field (Supplier's Identification Info).";
            }
            else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierBRNo)) && that._sanitiseInput(document.SupplierBRNo).length > 20)
            {
                throw "Validation Error : Invalid Data (Supplier's Business Registration No.).";
            }
            else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierNRIC)) && that._sanitiseInput(document.SupplierNRIC).length > 12)
            {
                throw "Validation Error : Invalid Data (Supplier's NRIC No.).";
            }
            else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierArmyNo)) && that._sanitiseInput(document.SupplierArmyNo).length > 12)
            {
                throw "Validation Error : Invalid Data (Supplier's Army No.).";
            }
            else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierPassportNo)) && that._sanitiseInput(document.SupplierPassportNo).length > 12)
            {
                throw "Validation Error : Invalid Data (Supplier's Passport No.).";
            }
            else if (!that._isNullOrWhitespace(document.SupplierEmail) && !that._isValidRFC5321Email(document.SupplierEmail))
            {
                throw "Validation Error : Invalid Data (Supplier's Email).";
            }
            else if (!that._isNullOrWhitespace(document.SupplierPhone) && !that._isValidE164PhoneNumber(document.SupplierPhone))
            {
                throw "Validation Error : Invalid Data (Supplier's Phone).";
            }
            else if (that._isNullOrWhitespace(that._generateIRBMCountryCode(document.SupplierAddressCountry)))
            {
                throw "Validation Error : Missing Required Field (Supplier's Country).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.SupplierMSIC)))
            {
                throw "Validation Error : Missing Required Field (Supplier's MSIC).";
            }
            else if (that._isNullOrWhitespace(that._sanitiseInput(document.SupplierMSICDescription)))
            {
                throw "Validation Error : Missing Required Field (Supplier's MSIC Description).";
            }
            else if (document.Details.reduce((n, { OrigTransAmt }) => n.Nadd((typeof OrigTransAmt == "number") ? OrigTransAmt : 0), 0) != 0)
            {
                throw "Validation Error : Invalid Detail Data (Document is not balanced).";
            }
            else if (document.Details.filter((detail) => ((typeof detail.Ind == "string") ? detail.Ind : "").startsWith("V")).length != 1)
            {
                throw "Validation Error : Invalid Detail Data (Missing/Duplicated Vendor Info).";
            }
            else if (document.Details.filter((detail) => ((typeof detail.Ind == "string") ? detail.Ind : "") == "V").length != 1)
            {
                throw "Validation Error : Invalid Detail Data (Missing/Duplicated Vendor Info).";
            }
            else if ((document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC")
                && document.Details.filter((detail) => (typeof detail.IsAdvance == "boolean") && detail.IsAdvance).length > 1)
            {
                throw "Validation Error : Unsupported Data Entry (IRBM only allows a single down payment for each settlement).";
            }
            else if ((document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC")
                && document.Details.some((detail) => (typeof detail.IsAdvance == "boolean") && detail.IsAdvance && ((typeof detail.DiscountAmt == "number") ? detail.DiscountAmt != 0 : false)))
            {
                throw "Validation Error : Unsupported Data Entry (Discount on down payment).";
            }
            else if (document.Details.reduce((n, detail) =>
            {
                let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                //if not tax, rounding, vendor line
                if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V"))
                {
                    return n.Nadd((document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC" || document.Source == "DN") ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                }
                else
                {
                    return n;
                }
            }, 0) < 0)
            {
                throw "Validation Error : Invalid Detail Data (Negative Document Amount).";
            }
            else if (document.Details.some((detail) =>
            {
                let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                if (Ind.startsWith("T"))
                {
                    //Base Line
                    let BaseLine = document.Details.find(x =>
                        (typeof x.TransDetKey == "number")
                        && x.TransDetKey > 0
                        && x.TransDetKey == ((typeof detail.RefTransDetKey == "number" && detail.RefTransDetKey > 0) ? detail.RefTransDetKey : -1)
                        && !((typeof x.Ind == "string") ? x.Ind : "").startsWith("T")
                        && !((typeof x.Ind == "string") ? x.Ind : "").startsWith("R")
                        && !((typeof x.Ind == "string") ? x.Ind : "").startsWith("V"));

                    let IsAdvance = (document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC") && (typeof BaseLine?.IsAdvance == "boolean") && BaseLine?.IsAdvance == true;

                    return (typeof detail.TransDetKey != "number") || detail.TransDetKey <= 0
                        || (typeof detail.OrigTransAmt != "number")
                        || (IsAdvance ? detail.OrigTransAmt < 0 : (((document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC" || document.Source == "DN") ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt) < 0))
                        || (typeof detail.RefTransDetKey != "number") || detail.RefTransDetKey <= 0
                        || (typeof detail.TaxPer != "number") || detail.TaxPer < 0
                        || (typeof detail.TaxType != "string") || (detail.TaxType.toLowerCase() != "Sales".toLowerCase() && detail.TaxType.toLowerCase() != "Services".toLowerCase())
                        || (typeof BaseLine == "undefined");
                }
                else if (Ind.startsWith("R"))
                {
                    return (typeof detail.TransDetKey != "number") || detail.TransDetKey <= 0
                        || (typeof detail.OrigTransAmt != "number");
                }
                else if (!Ind.startsWith("V"))
                {
                    let IsAdvance = (document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC") && (typeof detail?.IsAdvance == "boolean") && detail?.IsAdvance == true;

                    return (typeof detail.TransDetKey != "number") || detail.TransDetKey <= 0
                        || (typeof detail.OrigTransAmt != "number")
                        || (IsAdvance ? detail.OrigTransAmt < 0 : false)
                        || (typeof detail.DiscountAmt != "number") || detail.DiscountAmt < 0
                        || that._isNullOrWhitespace(that._sanitiseInput(detail.Classification))
                        || (typeof detail.UnitPrice != "number") || detail.UnitPrice <= 0
                        || (typeof detail.Qty != "number") || detail.Qty <= 0
                        || that._isNullOrWhitespace(detail.UOM);
                }
                else
                {
                    return (typeof detail.TransDetKey != "number") || detail.TransDetKey <= 0
                        || (typeof detail.OrigTransAmt != "number");
                }
            }))
            {
                throw "Validation Error : Missing/Invalid Detail Data (Qty/UOM/Unit Price/Amount/Tax).";
            }
            else if (!that._isNullOrWhitespace(document.CurrCode) && document.CurrCode != "MYR" && document.Details.some((detail) =>
            {
                let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";
                return Ind.startsWith("T") || Ind.startsWith("R");
            }))
            {
                //THIS IS VERY IMPORTANT, CANNOT ALLOW IF FOREIGN CURRENCY!!!!!!
                //Only allow multi currency document without Tax and Rounding Adjustment
                throw "Validation Error : Unsupported Data Entry (Tax/Rounding Adjustment in Foreign Currency).";
            }
        },

        /**
         * Format document to IRBM-acceptable XML format.
         *
         * @param {unformatted_document} document - Document to be formatted into IRBM acceptable XML format.
         * @param {Date} issueDate - Issue Date, optional, default to new date if not pass in
         * @returns {string}
         */
        _formatXMLDocument: function (document, issueDate)
        {
            let that = this;

            //e-invoice document generation time / IRBM's e-invoice submission time
            issueDate = ((typeof issueDate != "undefined") && (issueDate instanceof Date)) ? issueDate : new Date(Date.now());

            try
            {
                that._validateDocument(document);

                //header
                let UBL2_1_xml_formatted_document = '';

                //invoice header
                UBL2_1_xml_formatted_document += '<Invoice ';

                UBL2_1_xml_formatted_document += 'xmlns="urn:oasis:names:specification:ubl:schema:xsd:Invoice-2" ';
                UBL2_1_xml_formatted_document += 'xmlns:cac="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2" ';
                UBL2_1_xml_formatted_document += 'xmlns:cbc="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2" ';
                UBL2_1_xml_formatted_document += 'xmlns:ext="urn:oasis:names:specification:ubl:schema:xsd:CommonExtensionComponents-2"';
                UBL2_1_xml_formatted_document += '>';

                UBL2_1_xml_formatted_document += '<cbc:ID>' + ("(" + document.OUCode + ")" + document.DocNum) + '</cbc:ID>'; //prepend OUCode to ensure uniqueness, this should be same as codeNumber below
                UBL2_1_xml_formatted_document += '<cbc:IssueDate>' + issueDate.toISOString().split('T')[0] + '</cbc:IssueDate>';
                UBL2_1_xml_formatted_document += '<cbc:IssueTime>' + issueDate.toISOString().split('T')[1].split('.')[0] + "Z" + '</cbc:IssueTime>';

                // Type of Invoice                  Mapping                                                     Explanation
                // 01 - Invoice                     Sales Invoice (SIE, SIC, SI)                                Seller (you) to issue invoice
                // 02 - Credit Note                 Sales Credit Note (CN) applies SIE, SIC, SI                 Seller (you) want to drop invoice price
                // 03 - Debit Note                  Sales Debit Note (DN) applies SIE, SIC, SI                  Seller (you) want to raise invoice price
                // 04 - Refund Note                 Sales Credit Note (CN with Refund) applies SIE, SIC, SI     Seller (you) want to refund buyer(not you)
                // 11 - Self-billed Invoice         Self-Billed Invoice (SB)                                    Buyer (you) to issue self-billed as Seller (not you) not able to do so
                // 12 - Self-billed Credit Note     Purchase Credit Note (CN) applies SB                        Buyer (you) to issue self-billed as Seller (not you) not able to do so  Seller (not you) want to raise invoice price
                // 13 - Self-billed Debit Note      Purchase Debit Note (DN) applies SB                         Buyer (you) to issue self-billed as Seller (not you) not able to do so  Seller (not you) want to drop invoice price
                // 14 - Self-billed Refund Note     Purchase Debit Note (DN with Refund) applies SB             Buyer (you) to issue self-billed as Seller (not you) not able to do so  Buyer (you) receive refund from seller (not you)

                //Invoice is a commercial document issued by Supplier to itemise and record a transaction with Buyer.
                if (document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC")
                {
                    UBL2_1_xml_formatted_document += '<cbc:InvoiceTypeCode listVersionID="' + that._authObject.versionID + '">01</cbc:InvoiceTypeCode>';
                }
                //Credit note is the document issued by Suppliers to correct errors, apply discounts, or account for returns in a previously issued e-Invoice with the purpose of reducing the value of the original e-Invoice. This is used in situations where the reduction of the original e-Invoice does not involve return of monies to the Buyer.
                else if (document.Source == "CN")
                {
                    if (document.CDType == "PUR")
                    {
                        UBL2_1_xml_formatted_document += '<cbc:InvoiceTypeCode listVersionID="' + that._authObject.versionID + '">12</cbc:InvoiceTypeCode>';
                    }
                    else
                    {
                        if ((typeof document.IsRefund == "boolean") && document.IsRefund == true)
                        {
                            UBL2_1_xml_formatted_document += '<cbc:InvoiceTypeCode listVersionID="' + that._authObject.versionID + '">04</cbc:InvoiceTypeCode>';
                        }
                        else
                        {
                            UBL2_1_xml_formatted_document += '<cbc:InvoiceTypeCode listVersionID="' + that._authObject.versionID + '">02</cbc:InvoiceTypeCode>';
                        }
                    }
                }
                //Debit note is the document issued to indicate additional charges on a previously issued e-Invoice.
                else if (document.Source == "DN")
                {
                    if (document.CDType == "PUR")
                    {
                        if ((typeof document.IsRefund == "boolean") && document.IsRefund == true)
                        {
                            UBL2_1_xml_formatted_document += '<cbc:InvoiceTypeCode listVersionID="' + that._authObject.versionID + '">14</cbc:InvoiceTypeCode>';
                        }
                        else
                        {
                            UBL2_1_xml_formatted_document += '<cbc:InvoiceTypeCode listVersionID="' + that._authObject.versionID + '">13</cbc:InvoiceTypeCode>';
                        }
                    }
                    else
                    {
                        UBL2_1_xml_formatted_document += '<cbc:InvoiceTypeCode listVersionID="' + that._authObject.versionID + '">03</cbc:InvoiceTypeCode>';
                    }
                }
                //There are certain circumstances where another party (other than the Supplier) will be allowed to issue a self-billed e-Invoice. Kindly refer to Section 8 of the e-Invoice Specific Guideline where self-billed e-Invoice will be allowed.
                //Self - Billed Invoice refers to the initial self - billed e - Invoice that will be issued by the Buyer.
                else if (document.Source == "SB")
                {
                    UBL2_1_xml_formatted_document += '<cbc:InvoiceTypeCode listVersionID="' + that._authObject.versionID + '">11</cbc:InvoiceTypeCode>';
                }

                UBL2_1_xml_formatted_document += '<cbc:DocumentCurrencyCode>' + that._generateIRBMCurrencyCode(document.CurrCode) + '</cbc:DocumentCurrencyCode>';
                UBL2_1_xml_formatted_document += '<cbc:TaxCurrencyCode>MYR</cbc:TaxCurrencyCode>'; //Quarto's tax line always MYR

                //UBL2_1_xml_formatted_document += '<cac:InvoicePeriod>';
                //UBL2_1_xml_formatted_document += '<cbc:StartDate>' + (new Date(document.InvoiceDate).toISOString().split('T')[0]) + '</cbc:StartDate>';
                //UBL2_1_xml_formatted_document += '<cbc:EndDate>' + (new Date(document.InvoiceDate).toISOString().split('T')[0]) + '</cbc:EndDate>';
                //UBL2_1_xml_formatted_document += '<cbc:Description>Not Applicable</cbc:Description>';
                //UBL2_1_xml_formatted_document += '</cac:InvoicePeriod>';

                //References
                UBL2_1_xml_formatted_document += '<cac:BillingReference>';
                UBL2_1_xml_formatted_document += '<cac:AdditionalDocumentReference>';
                UBL2_1_xml_formatted_document += '<cbc:ID>' + ((that._isNullOrWhitespace(document.InvNum) || document.InvNum.trim().toUpperCase() == "NA") ? "NA" : document.InvNum.trim()) + '</cbc:ID>';
                UBL2_1_xml_formatted_document += '</cac:AdditionalDocumentReference>';
                UBL2_1_xml_formatted_document += '</cac:BillingReference>';

                //Invoice is a commercial document issued by Supplier to itemise and record a transaction with Buyer.
                if (document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC")
                {
                    //do not require this section
                }
                //Credit note is the document issued by Suppliers to correct errors, apply discounts, or account for returns in a previously issued e-Invoice with the purpose of reducing the value of the original e-Invoice. This is used in situations where the reduction of the original e-Invoice does not involve return of monies to the Buyer.
                else if (document.Source == "CN")
                {
                    if (document.Details.some(x => !that._isNullOrWhitespace(x.RefDocNum) && x.RefDocNum.trim().toUpperCase() != 'NA'))
                    {
                        document.Details.filter(x => !that._isNullOrWhitespace(x.RefDocNum) && x.RefDocNum.trim().toUpperCase() != 'NA').reduce((prev, curr) =>
                        {
                            if (prev.every(z => z.RefDocNum != curr.RefDocNum.trim()))
                            {
                                prev.push({
                                    RefDocNum: curr.RefDocNum.trim(),
                                    RefIRBMNum: ((that._isNullOrWhitespace(curr.RefIRBMNum) || curr.RefIRBMNum.trim().toUpperCase() == 'NA') ? 'NA' : curr.RefIRBMNum)
                                });
                            }

                            return prev;
                        }, []).forEach(y =>
                        {
                            UBL2_1_xml_formatted_document += '<cac:BillingReference>';
                            UBL2_1_xml_formatted_document += '<cac:InvoiceDocumentReference>';
                            UBL2_1_xml_formatted_document += '<cbc:ID>' + ("(" + document.OUCode + ")" + y.RefDocNum) + '</cbc:ID>'; //prepend OUCode to ensure uniqueness
                            UBL2_1_xml_formatted_document += '<cbc:UUID>' + y.RefIRBMNum + '</cbc:UUID>';
                            UBL2_1_xml_formatted_document += '</cac:InvoiceDocumentReference>';
                            UBL2_1_xml_formatted_document += '</cac:BillingReference>';
                        });
                    }
                    else
                    {
                        UBL2_1_xml_formatted_document += '<cac:BillingReference>';
                        UBL2_1_xml_formatted_document += '<cac:InvoiceDocumentReference>';
                        UBL2_1_xml_formatted_document += '<cbc:ID>' + 'NA' + '</cbc:ID>';
                        UBL2_1_xml_formatted_document += '<cbc:UUID>' + "NA" + '</cbc:UUID>';
                        UBL2_1_xml_formatted_document += '</cac:InvoiceDocumentReference>';
                        UBL2_1_xml_formatted_document += '</cac:BillingReference>';
                    }
                }
                //Debit note is the document issued to indicate additional charges on a previously issued e-Invoice.
                else if (document.Source == "DN")
                {
                    if (document.Details.some(x => !that._isNullOrWhitespace(x.RefDocNum) && x.RefDocNum.trim().toUpperCase() != 'NA'))
                    {
                        document.Details.filter(x => !that._isNullOrWhitespace(x.RefDocNum) && x.RefDocNum.trim().toUpperCase() != 'NA').reduce((prev, curr) =>
                        {
                            if (prev.every(z => z.RefDocNum != curr.RefDocNum.trim()))
                            {
                                prev.push({
                                    RefDocNum: curr.RefDocNum.trim(),
                                    RefIRBMNum: ((that._isNullOrWhitespace(curr.RefIRBMNum) || curr.RefIRBMNum.trim().toUpperCase() == 'NA') ? 'NA' : curr.RefIRBMNum)
                                });
                            }

                            return prev;
                        }, []).forEach(y =>
                        {
                            UBL2_1_xml_formatted_document += '<cac:BillingReference>';
                            UBL2_1_xml_formatted_document += '<cac:InvoiceDocumentReference>';
                            UBL2_1_xml_formatted_document += '<cbc:ID>' + ("(" + document.OUCode + ")" + y.RefDocNum) + '</cbc:ID>'; //prepend OUCode to ensure uniqueness
                            UBL2_1_xml_formatted_document += '<cbc:UUID>' + y.RefIRBMNum + '</cbc:UUID>';
                            UBL2_1_xml_formatted_document += '</cac:InvoiceDocumentReference>';
                            UBL2_1_xml_formatted_document += '</cac:BillingReference>';
                        });
                    }
                    else
                    {
                        UBL2_1_xml_formatted_document += '<cac:BillingReference>';
                        UBL2_1_xml_formatted_document += '<cac:InvoiceDocumentReference>';
                        UBL2_1_xml_formatted_document += '<cbc:ID>' + 'NA' + '</cbc:ID>';
                        UBL2_1_xml_formatted_document += '<cbc:UUID>' + "NA" + '</cbc:UUID>';
                        UBL2_1_xml_formatted_document += '</cac:InvoiceDocumentReference>';
                        UBL2_1_xml_formatted_document += '</cac:BillingReference>';
                    }
                }
                //There are certain circumstances where another party (other than the Supplier) will be allowed to issue a self-billed e-Invoice. Kindly refer to Section 8 of the e-Invoice Specific Guideline where self-billed e-Invoice will be allowed.
                //Self - Billed Invoice refers to the initial self - billed e - Invoice that will be issued by the Buyer.
                else if (document.Source == "SB")
                {
                    //do not require this section
                }

                //Supplier
                UBL2_1_xml_formatted_document += '<cac:AccountingSupplierParty>';
                UBL2_1_xml_formatted_document += '<cac:Party>';

                UBL2_1_xml_formatted_document += '<cbc:IndustryClassificationCode name="' + that._sanitiseInput(document.SupplierMSICDescription) + '">' + that._sanitiseInput(document.SupplierMSIC) + '</cbc:IndustryClassificationCode>';

                UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                UBL2_1_xml_formatted_document += '<cbc:ID schemeID="TIN">' + that._sanitiseInput(document.SupplierTIN) + '</cbc:ID>';
                UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';

                //must have either one
                if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierBRNo)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="BRN">' + that._sanitiseInput(document.SupplierBRNo) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierNRIC)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="NRIC">' + that._sanitiseInput(document.SupplierNRIC) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierPassportNo)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="PASSPORT">' + that._sanitiseInput(document.SupplierPassportNo) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierArmyNo)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="ARMY">' + that._sanitiseInput(document.SupplierArmyNo) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else
                {
                    throw "Validation Error.";
                }

                //SST
                if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierSST)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="SST">' + that._sanitiseInput(document.SupplierSST) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="SST">NA</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }

                //TT
                UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                UBL2_1_xml_formatted_document += '<cbc:ID schemeID="TTX">NA</cbc:ID>';
                UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';

                UBL2_1_xml_formatted_document += '<cac:PostalAddress>';
                UBL2_1_xml_formatted_document += '<cbc:CityName>' + (that._isNullOrWhitespace(that._sanitiseInput(document.SupplierAddressCity)) ? 'NA' : that._sanitiseInput(document.SupplierAddressCity)) + '</cbc:CityName>';
                UBL2_1_xml_formatted_document += '<cbc:PostalZone>' + (that._isNullOrWhitespace(that._sanitiseInput(document.SupplierAddressPostCode)) ? 'NA' : that._sanitiseInput(document.SupplierAddressPostCode)) + '</cbc:PostalZone>';
                UBL2_1_xml_formatted_document += '<cbc:CountrySubentityCode>' + that._generateIRBMStateCode(that._sanitiseInput(document.SupplierAddressState)) + '</cbc:CountrySubentityCode>';
                UBL2_1_xml_formatted_document += '<cac:AddressLine>';
                UBL2_1_xml_formatted_document += '<cbc:Line>' + (that._isNullOrWhitespace(that._sanitiseInput(document.SupplierAddressStreet)) ? 'NA' : that._sanitiseInput(document.SupplierAddressStreet)) + '</cbc:Line>';
                UBL2_1_xml_formatted_document += '</cac:AddressLine>';
                UBL2_1_xml_formatted_document += '<cac:Country>';
                UBL2_1_xml_formatted_document += '<cbc:IdentificationCode listID="ISO3166-1" listAgencyID="6">' + that._generateIRBMCountryCode(that._sanitiseInput(document.SupplierAddressCountry)) + '</cbc:IdentificationCode>';
                UBL2_1_xml_formatted_document += '</cac:Country>';
                UBL2_1_xml_formatted_document += '</cac:PostalAddress>';

                UBL2_1_xml_formatted_document += '<cac:PartyLegalEntity>';
                UBL2_1_xml_formatted_document += '<cbc:RegistrationName>' + that._sanitiseInput(document.SupplierName).substring(0, 300) + '</cbc:RegistrationName>';
                UBL2_1_xml_formatted_document += '</cac:PartyLegalEntity>';

                UBL2_1_xml_formatted_document += '<cac:Contact>';
                UBL2_1_xml_formatted_document += '<cbc:Telephone>' + (that._isValidE164PhoneNumber(document.SupplierPhone) ? that._extractValidE164PhoneNumber(document.SupplierPhone) : 'NA') + '</cbc:Telephone>';
                UBL2_1_xml_formatted_document += '<cbc:ElectronicMail>' + (that._isValidRFC5321Email(that._sanitiseInput(document.SupplierEmail)) ? that._sanitiseInput(document.SupplierEmail) : 'NA') + '</cbc:ElectronicMail>';
                UBL2_1_xml_formatted_document += '</cac:Contact>';

                UBL2_1_xml_formatted_document += '</cac:Party>';
                UBL2_1_xml_formatted_document += '</cac:AccountingSupplierParty>';

                //Buyer
                UBL2_1_xml_formatted_document += '<cac:AccountingCustomerParty>';
                UBL2_1_xml_formatted_document += '<cac:Party>';

                UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                UBL2_1_xml_formatted_document += '<cbc:ID schemeID="TIN">' + that._sanitiseInput(document.BuyerTIN) + '</cbc:ID>';
                UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';

                //must have either one
                if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerBRNo)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="BRN">' + that._sanitiseInput(document.BuyerBRNo) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerNRIC)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="NRIC">' + that._sanitiseInput(document.BuyerNRIC) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerPassportNo)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="PASSPORT">' + that._sanitiseInput(document.BuyerPassportNo) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerArmyNo)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="ARMY">' + that._sanitiseInput(document.BuyerArmyNo) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else
                {
                    throw "Validation Error.";
                }

                //SST
                if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerSST)))
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="SST">' + that._sanitiseInput(document.BuyerSST) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }
                else
                {
                    UBL2_1_xml_formatted_document += '<cac:PartyIdentification>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="SST">NA</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:PartyIdentification>';
                }

                UBL2_1_xml_formatted_document += '<cac:PostalAddress>';
                UBL2_1_xml_formatted_document += '<cbc:CityName>' + (that._isNullOrWhitespace(that._sanitiseInput(document.BuyerAddressCity)) ? 'NA' : that._sanitiseInput(document.BuyerAddressCity)) + '</cbc:CityName>';
                UBL2_1_xml_formatted_document += '<cbc:PostalZone>' + (that._isNullOrWhitespace(that._sanitiseInput(document.BuyerAddressPostCode)) ? 'NA' : that._sanitiseInput(document.BuyerAddressPostCode)) + '</cbc:PostalZone>';
                UBL2_1_xml_formatted_document += '<cbc:CountrySubentityCode>' + that._generateIRBMStateCode(that._sanitiseInput(document.BuyerAddressState)) + '</cbc:CountrySubentityCode>';
                UBL2_1_xml_formatted_document += '<cac:AddressLine>';
                UBL2_1_xml_formatted_document += '<cbc:Line>' + (that._isNullOrWhitespace(that._sanitiseInput(document.BuyerAddressStreet)) ? 'NA' : that._sanitiseInput(document.BuyerAddressStreet)) + '</cbc:Line>';
                UBL2_1_xml_formatted_document += '</cac:AddressLine>';
                UBL2_1_xml_formatted_document += '<cac:Country>';
                UBL2_1_xml_formatted_document += '<cbc:IdentificationCode listID="ISO3166-1" listAgencyID="6">' + that._generateIRBMCountryCode(that._sanitiseInput(document.BuyerAddressCountry)) + '</cbc:IdentificationCode>';
                UBL2_1_xml_formatted_document += '</cac:Country>';
                UBL2_1_xml_formatted_document += '</cac:PostalAddress>';

                UBL2_1_xml_formatted_document += '<cac:PartyLegalEntity>';
                UBL2_1_xml_formatted_document += '<cbc:RegistrationName>' + that._sanitiseInput(document.BuyerName).substring(0, 300) + '</cbc:RegistrationName>';
                UBL2_1_xml_formatted_document += '</cac:PartyLegalEntity>';

                UBL2_1_xml_formatted_document += '<cac:Contact>';
                UBL2_1_xml_formatted_document += '<cbc:Telephone>' + (that._isValidE164PhoneNumber(document.BuyerPhone) ? that._extractValidE164PhoneNumber(document.BuyerPhone) : 'NA') + '</cbc:Telephone>';
                UBL2_1_xml_formatted_document += '<cbc:ElectronicMail>' + (that._isValidRFC5321Email(that._sanitiseInput(document.BuyerEmail)) ? that._sanitiseInput(document.BuyerEmail) : 'NA') + '</cbc:ElectronicMail>';
                UBL2_1_xml_formatted_document += '</cac:Contact>';

                UBL2_1_xml_formatted_document += '</cac:Party>';
                UBL2_1_xml_formatted_document += '</cac:AccountingCustomerParty>';

                //Payment method
                //UBL2_1_xml_formatted_document += '<cac:PaymentMeans>';
                //UBL2_1_xml_formatted_document += '<cbc:PaymentMeansCode>08</cbc:PaymentMeansCode>'; //Payment Mode
                //UBL2_1_xml_formatted_document += '<cac:PayeeFinancialAccount>';
                //UBL2_1_xml_formatted_document += '<cbc:ID>Supplier bank account here</cbc:ID>';
                //UBL2_1_xml_formatted_document += '</cac:PayeeFinancialAccount>';
                //UBL2_1_xml_formatted_document += '</cac:PaymentMeans>';

                //Payment Terms and Condition
                UBL2_1_xml_formatted_document += '<cac:PaymentTerms>';
                UBL2_1_xml_formatted_document += '<cbc:Note>' + that._sanitiseInput(document.PayTermDesc).substring(0, 300) + '</cbc:Note>';
                UBL2_1_xml_formatted_document += '</cac:PaymentTerms>';

                // Types of amount :
                //      1. Allowance (Discount)                => LegalMonetaryTotal > AllowanceTotalAmount
                //      2. Prepaid (Advance Payment)           => PrepaidPayment > PaidAmount
                //      3. Rounding                            => LegalMonetaryTotal > PayableRoundingAmount
                //      4. Charge (Fee) ie. Processing Fee     => LegalMonetaryTotal > ChargeTotalAmount
                //      5. VAT                                 => TaxTotal > TaxAmount
                //
                // Types of summation :
                //      1. LegalMonetaryTotal > LineExtensionAmount => Summation of invoice detail
                //                                                     ✔ Allowance (Discount)
                //                                                     ✘ Prepaid (Advance Payment)
                //                                                     ✘ Rounding
                //                                                     ✔ Charge (Fee)
                //                                                     ✘ VAT
                //      2. LegalMonetaryTotal > TaxExclusiveAmount  => Summation of invoice detail plus header level but Quarto don't have header level amount so it is same as LineExtensionAmount
                //                                                     ✔ Allowance (Discount)
                //                                                     ✘ Prepaid (Advance Payment)
                //                                                     ✘ Rounding
                //                                                     ✔ Charge (Fee)
                //                                                     ✘ VAT
                //      3. LegalMonetaryTotal > TaxInclusiveAmount  => TaxExclusiveAmount plus VAT
                //                                                     ✔ Allowance (Discount)
                //                                                     ✘ Prepaid (Advance Payment)
                //                                                     ✘ Rounding
                //                                                     ✔ Charge (Fee)
                //                                                     ✔ VAT
                //      4. LegalMonetaryTotal > PayableAmount       => Summation of everything
                //                                                     ✔ Allowance (Discount)
                //                                                     ✔ Prepaid (Advance Payment)
                //                                                     ✔ Rounding
                //                                                     ✔ Charge (Fee)
                //                                                     ✔ VAT

                //flip if account receivable side
                let IsFlip = document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC" || document.Source == "DN";

                //is contain prepayment
                let IsAdvance = (document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC") && document.Details.some(detail => (typeof detail.IsAdvance == "boolean") && detail.IsAdvance);

                let TotalBaseAmountInclusiveDiscount = document.Details.reduce((n, detail) =>
                {
                    let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                    //if not tax, rounding, vendor line, prepayment
                    if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V") && !(IsAdvance && (typeof detail.IsAdvance == "boolean") && detail.IsAdvance))
                    {
                        return n.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                    }
                    else
                    {
                        return n;
                    }
                }, 0);

                let TotalBaseDiscount = document.Details.reduce((n, detail) =>
                {
                    let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                    //if not tax, rounding, vendor line, prepayment
                    if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V") && !(IsAdvance && (typeof detail.IsAdvance == "boolean") && detail.IsAdvance))
                    {
                        //discount aways store as positive, need to flip accordingly
                        if (IsFlip && detail.OrigTransAmt >= 0)
                        {
                            return n.Nadd(detail.DiscountAmt.Nflip());
                        }
                        else if (IsFlip && detail.OrigTransAmt < 0)
                        {
                            return n.Nadd(detail.DiscountAmt);
                        }
                        else if (!IsFlip && detail.OrigTransAmt >= 0)
                        {
                            return n.Nadd(detail.DiscountAmt);
                        }
                        else if (!IsFlip && detail.OrigTransAmt < 0)
                        {
                            return n.Nadd(detail.DiscountAmt.Nflip());
                        }
                    }
                    else
                    {
                        return n;
                    }
                }, 0);

                //Sales Tax
                let TotalSalesBaseAmount = 0;
                let TotalSalesTax = 0;
                let TotalSalesTaxCount = 0;

                //Service Tax
                let TotalServiceBaseAmount = 0;
                let TotalServiceTax = 0;
                let TotalServiceTaxCount = 0;

                //Exemption Tax
                let TotalExemptionBaseAmount = 0;
                let TotalExemptionTax = 0;
                let TotalExemptionTaxCount = 0;

                //Rounding
                let TotalRounding = 0;

                //Fee
                let TotalCharges = 0;

                //Prepaid
                let TotalPrepaid = 0;

                document.Details.forEach(detail =>
                {
                    let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                    //if base line (not tax, rounding, vendor line)
                    if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V"))
                    {
                        //Tax Line
                        let TaxLine = document.Details.find(x => x.RefTransDetKey == detail.TransDetKey && ((typeof x.Ind == "string") ? x.Ind : "").startsWith("T"));

                        //Prepayment (Tax amount from prepayment is consider part of prepayment)
                        if (IsAdvance && (typeof detail.IsAdvance == "boolean") && detail.IsAdvance)
                        {
                            //prepayment is in opposite nature
                            TotalPrepaid = TotalPrepaid.Nadd(IsFlip ? detail.OrigTransAmt : detail.OrigTransAmt.Nflip());

                            if (typeof TaxLine?.TaxType == "string"
                                && TaxLine.OrigTransAmt != 0)
                            {
                                TotalPrepaid = TotalPrepaid.Nadd(IsFlip ? TaxLine.OrigTransAmt : TaxLine.OrigTransAmt.Nflip());
                            }
                        }
                        //Sales Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase()
                            && TaxLine.OrigTransAmt != 0)
                        {
                            TotalSalesBaseAmount = TotalSalesBaseAmount.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                            TotalSalesTax = TotalSalesTax.Nadd(IsFlip ? TaxLine.OrigTransAmt.Nflip() : TaxLine.OrigTransAmt);
                            TotalSalesTaxCount = TotalSalesTaxCount + 1;
                        }
                        //Service Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && TaxLine.TaxType.toLowerCase() == "Services".toLowerCase()
                            && TaxLine.OrigTransAmt != 0)
                        {
                            TotalServiceBaseAmount = TotalServiceBaseAmount.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                            TotalServiceTax = TotalServiceTax.Nadd(IsFlip ? TaxLine.OrigTransAmt.Nflip() : TaxLine.OrigTransAmt);
                            TotalServiceTaxCount = TotalServiceTaxCount + 1;
                        }
                        //Exemption Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && (TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase() || TaxLine.TaxType.toLowerCase() == "Services".toLowerCase())
                            && TaxLine.OrigTransAmt == 0)
                        {
                            TotalExemptionBaseAmount = TotalExemptionBaseAmount.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                            TotalExemptionTax = 0;
                            TotalExemptionTaxCount = TotalExemptionTaxCount + 1;
                        }
                    }
                    //if rounding line
                    else if (Ind.startsWith("R"))
                    {
                        TotalRounding = TotalRounding.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                    }
                });

                //Prepaid or Advance Payment
                if (IsAdvance)
                {
                    let AdvanceLine = document.Details.find(detail => (typeof detail.IsAdvance == "boolean") && detail.IsAdvance);

                    UBL2_1_xml_formatted_document += '<cac:PrepaidPayment>';
                    UBL2_1_xml_formatted_document += '<cbc:ID>' + ((that._isNullOrWhitespace(AdvanceLine?.AdvanceDocNum) || AdvanceLine.AdvanceDocNum.trim().toUpperCase() == 'NA') ? 'NA' : ("(" + document.OUCode + ")" + AdvanceLine.AdvanceDocNum.trim())) + '</cbc:ID>';
                    UBL2_1_xml_formatted_document += '<cbc:PaidAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalPrepaid + '</cbc:PaidAmount>';

                    //kendo won't parse nested object, date will appear as string in nested object, need to manually validate with momentjs
                    if ((moment(AdvanceLine?.AdvanceInvoiceDate, "YYYY-MM-DDTHH:mm:ss", true)).isValid()
                        && ((new Date(AdvanceLine?.AdvanceInvoiceDate)).getFullYear() > 1900
                            && (new Date(AdvanceLine?.AdvanceInvoiceDate)).getFullYear() < 2999))
                    {
                        if (AdvanceLine.AdvanceInvoiceDate instanceof Date)
                        {
                            UBL2_1_xml_formatted_document += '<cbc:PaidDate>' + AdvanceLine.AdvanceInvoiceDate.toISOString().split('T')[0] + '</cbc:PaidDate>';
                            UBL2_1_xml_formatted_document += '<cbc:PaidTime>' + AdvanceLine.AdvanceInvoiceDate.toISOString().split('T')[1].split('.')[0] + "Z" + '</cbc:PaidTime>';
                        }
                        else if (typeof AdvanceLine.AdvanceInvoiceDate == "string")
                        {
                            UBL2_1_xml_formatted_document += '<cbc:PaidDate>' + AdvanceLine.AdvanceInvoiceDate.split('T')[0] + '</cbc:PaidDate>';
                            UBL2_1_xml_formatted_document += '<cbc:PaidTime>' + AdvanceLine.AdvanceInvoiceDate.split('T')[1].split('.')[0] + "Z" + '</cbc:PaidTime>';
                        }
                    }

                    UBL2_1_xml_formatted_document += '</cac:PrepaidPayment>';
                }

                //Tax Exchange Rate
                UBL2_1_xml_formatted_document += '<cac:TaxExchangeRate>';
                UBL2_1_xml_formatted_document += '<cbc:SourceCurrencyCode>' + that._generateIRBMCurrencyCode(document.CurrCode) + '</cbc:SourceCurrencyCode>';
                UBL2_1_xml_formatted_document += '<cbc:TargetCurrencyCode>MYR</cbc:TargetCurrencyCode>';
                UBL2_1_xml_formatted_document += '<cbc:CalculationRate>' + document.ExRateFunc.NtoDP(6) + '</cbc:CalculationRate>';
                UBL2_1_xml_formatted_document += '<cbc:MathematicOperatorCode>' + (document.ConvertType == "M" ? '*' : '/') + '</cbc:MathematicOperatorCode>';
                UBL2_1_xml_formatted_document += '<cbc:Date>' + (new Date(document.GLDate).toISOString().split('T')[0]) + '</cbc:Date>';
                UBL2_1_xml_formatted_document += '</cac:TaxExchangeRate>';

                //Taxes Summation
                UBL2_1_xml_formatted_document += '<cac:TaxTotal>';
                UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalSalesTax.Nadd(TotalServiceTax) + '</cbc:TaxAmount>';

                if (TotalSalesTaxCount > 0)
                {
                    UBL2_1_xml_formatted_document += '<cac:TaxSubtotal>';
                    UBL2_1_xml_formatted_document += '<cbc:TaxableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalSalesBaseAmount + '</cbc:TaxableAmount>';
                    UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalSalesTax + '</cbc:TaxAmount>';
                    UBL2_1_xml_formatted_document += '<cac:TaxCategory>';
                    UBL2_1_xml_formatted_document += '<cbc:ID>01</cbc:ID>'; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                    UBL2_1_xml_formatted_document += '<cac:TaxScheme>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="UN/ECE 5153" schemeAgencyID="6">OTH</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:TaxScheme>';
                    UBL2_1_xml_formatted_document += '</cac:TaxCategory>';
                    UBL2_1_xml_formatted_document += '</cac:TaxSubtotal>';
                }

                if (TotalServiceTaxCount > 0)
                {
                    UBL2_1_xml_formatted_document += '<cac:TaxSubtotal>';
                    UBL2_1_xml_formatted_document += '<cbc:TaxableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalServiceBaseAmount + '</cbc:TaxableAmount>';
                    UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalServiceTax + '</cbc:TaxAmount>';
                    UBL2_1_xml_formatted_document += '<cac:TaxCategory>';
                    UBL2_1_xml_formatted_document += '<cbc:ID>02</cbc:ID>'; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                    UBL2_1_xml_formatted_document += '<cac:TaxScheme>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="UN/ECE 5153" schemeAgencyID="6">OTH</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:TaxScheme>';
                    UBL2_1_xml_formatted_document += '</cac:TaxCategory>';
                    UBL2_1_xml_formatted_document += '</cac:TaxSubtotal>';
                }

                if (TotalExemptionTaxCount > 0)
                {
                    UBL2_1_xml_formatted_document += '<cac:TaxSubtotal>';
                    UBL2_1_xml_formatted_document += '<cbc:TaxableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalExemptionBaseAmount + '</cbc:TaxableAmount>';
                    UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalExemptionTax + '</cbc:TaxAmount>';
                    UBL2_1_xml_formatted_document += '<cac:TaxCategory>';
                    UBL2_1_xml_formatted_document += '<cbc:ID>E</cbc:ID>'; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                    UBL2_1_xml_formatted_document += '<cbc:TaxExemptionReason>Exempted Taxable Goods</cbc:TaxExemptionReason>';
                    UBL2_1_xml_formatted_document += '<cac:TaxScheme>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="UN/ECE 5153" schemeAgencyID="6">OTH</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:TaxScheme>';
                    UBL2_1_xml_formatted_document += '</cac:TaxCategory>';
                    UBL2_1_xml_formatted_document += '</cac:TaxSubtotal>';
                }

                if (TotalSalesTaxCount == 0 && TotalServiceTaxCount == 0 && TotalExemptionTaxCount == 0)
                {
                    UBL2_1_xml_formatted_document += '<cac:TaxSubtotal>';
                    UBL2_1_xml_formatted_document += '<cbc:TaxableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalBaseAmountInclusiveDiscount + '</cbc:TaxableAmount>';
                    UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + 0 + '</cbc:TaxAmount>';
                    UBL2_1_xml_formatted_document += '<cac:TaxCategory>';
                    UBL2_1_xml_formatted_document += '<cbc:ID>06</cbc:ID>'; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                    UBL2_1_xml_formatted_document += '<cac:TaxScheme>';
                    UBL2_1_xml_formatted_document += '<cbc:ID schemeID="UN/ECE 5153" schemeAgencyID="6">OTH</cbc:ID>';
                    UBL2_1_xml_formatted_document += '</cac:TaxScheme>';
                    UBL2_1_xml_formatted_document += '</cac:TaxCategory>';
                    UBL2_1_xml_formatted_document += '</cac:TaxSubtotal>';
                }

                UBL2_1_xml_formatted_document += '</cac:TaxTotal>';

                //Summations
                UBL2_1_xml_formatted_document += '<cac:LegalMonetaryTotal>';
                UBL2_1_xml_formatted_document += '<cbc:LineExtensionAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalBaseAmountInclusiveDiscount.Nadd(TotalCharges) + '</cbc:LineExtensionAmount>';
                UBL2_1_xml_formatted_document += '<cbc:TaxExclusiveAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalBaseAmountInclusiveDiscount.Nadd(TotalCharges) + '</cbc:TaxExclusiveAmount>';
                UBL2_1_xml_formatted_document += '<cbc:TaxInclusiveAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalBaseAmountInclusiveDiscount.Nadd(TotalCharges).Nadd(TotalSalesTax).Nadd(TotalServiceTax) + '</cbc:TaxInclusiveAmount>';
                UBL2_1_xml_formatted_document += '<cbc:AllowanceTotalAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalBaseDiscount + '</cbc:AllowanceTotalAmount>';
                UBL2_1_xml_formatted_document += '<cbc:ChargeTotalAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalCharges + '</cbc:ChargeTotalAmount>';
                UBL2_1_xml_formatted_document += '<cbc:PayableRoundingAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalRounding + '</cbc:PayableRoundingAmount>';
                UBL2_1_xml_formatted_document += '<cbc:PayableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + TotalBaseAmountInclusiveDiscount.Nadd(TotalCharges).Nadd(TotalSalesTax).Nadd(TotalServiceTax).Nadd(TotalRounding).Nminus(TotalPrepaid) + '</cbc:PayableAmount>';
                UBL2_1_xml_formatted_document += '</cac:LegalMonetaryTotal>';

                //Invoice Line
                let index = 0;
                document.Details.forEach((detail) =>
                {
                    let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                    //if base line (not tax, rounding, vendor line, prepayment)
                    if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V") && !(IsAdvance && (typeof detail.IsAdvance == "boolean") && detail.IsAdvance))
                    {
                        //add back discounted amount to get back amount before discount
                        let BaseAmountExclusiveDiscount = (IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt).Nadd(detail.DiscountAmt);
                        let BaseDiscount = detail.DiscountAmt;
                        let BaseAmountInclusiveDiscount = IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt;

                        //Tax Line
                        let TaxLine = document.Details.find(x => x.RefTransDetKey == detail.TransDetKey && ((typeof x.Ind == "string") ? x.Ind : "").startsWith("T"));
                        let Tax = (typeof TaxLine?.TaxType == "string" && (TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase() || TaxLine.TaxType.toLowerCase() == "Services".toLowerCase())) ? (IsFlip ? TaxLine.OrigTransAmt.Nflip() : TaxLine.OrigTransAmt) : 0;

                        UBL2_1_xml_formatted_document += '<cac:InvoiceLine>';

                        UBL2_1_xml_formatted_document += '<cbc:ID>' + (index + 1) + '</cbc:ID>';
                        UBL2_1_xml_formatted_document += '<cbc:InvoicedQuantity unitCode="' + that._generateIRBMUOMCode(detail.UOM) + '">' + detail.Qty + '</cbc:InvoicedQuantity>';
                        UBL2_1_xml_formatted_document += '<cbc:LineExtensionAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + BaseAmountInclusiveDiscount + '</cbc:LineExtensionAmount>';

                        UBL2_1_xml_formatted_document += '<cac:AllowanceCharge>';
                        UBL2_1_xml_formatted_document += '<cbc:ChargeIndicator>false</cbc:ChargeIndicator>';
                        UBL2_1_xml_formatted_document += '<cbc:AllowanceChargeReason>Discount Applied</cbc:AllowanceChargeReason>';
                        UBL2_1_xml_formatted_document += '<cbc:Amount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + BaseDiscount + '</cbc:Amount>';
                        UBL2_1_xml_formatted_document += '</cac:AllowanceCharge>';

                        UBL2_1_xml_formatted_document += '<cac:TaxTotal>';
                        UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + Tax + '</cbc:TaxAmount>';

                        //Sales Tax
                        if (typeof TaxLine?.TaxType == "string"
                            && TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase()
                            && Tax != 0)
                        {
                            UBL2_1_xml_formatted_document += '<cac:TaxSubtotal>';
                            UBL2_1_xml_formatted_document += '<cbc:TaxableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + BaseAmountInclusiveDiscount + '</cbc:TaxableAmount>';
                            UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + Tax + '</cbc:TaxAmount>';
                            UBL2_1_xml_formatted_document += '<cbc:BaseUnitMeasure unitCode="' + that._generateIRBMUOMCode(detail.UOM) + '">' + detail.Qty + '</cbc:BaseUnitMeasure>';
                            UBL2_1_xml_formatted_document += '<cbc:PerUnitAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + Tax.Ndiv2(detail.Qty) + '</cbc:PerUnitAmount>';
                            UBL2_1_xml_formatted_document += '<cac:TaxCategory>';
                            UBL2_1_xml_formatted_document += '<cbc:ID>01</cbc:ID>'; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                            UBL2_1_xml_formatted_document += '<cac:TaxScheme>';
                            UBL2_1_xml_formatted_document += '<cbc:ID schemeID="UN/ECE 5153" schemeAgencyID="6">OTH</cbc:ID>';
                            UBL2_1_xml_formatted_document += '</cac:TaxScheme>';
                            UBL2_1_xml_formatted_document += '</cac:TaxCategory>';
                            UBL2_1_xml_formatted_document += '</cac:TaxSubtotal>';
                        }
                        //Service Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && TaxLine.TaxType.toLowerCase() == "Services".toLowerCase()
                            && Tax != 0)
                        {
                            UBL2_1_xml_formatted_document += '<cac:TaxSubtotal>';
                            UBL2_1_xml_formatted_document += '<cbc:TaxableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + BaseAmountInclusiveDiscount + '</cbc:TaxableAmount>';
                            UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + Tax + '</cbc:TaxAmount>';
                            UBL2_1_xml_formatted_document += '<cbc:BaseUnitMeasure unitCode="' + that._generateIRBMUOMCode(detail.UOM) + '">' + detail.Qty + '</cbc:BaseUnitMeasure>';
                            UBL2_1_xml_formatted_document += '<cbc:PerUnitAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + Tax.Ndiv2(detail.Qty) + '</cbc:PerUnitAmount>';
                            UBL2_1_xml_formatted_document += '<cac:TaxCategory>';
                            UBL2_1_xml_formatted_document += '<cbc:ID>02</cbc:ID>'; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                            UBL2_1_xml_formatted_document += '<cac:TaxScheme>';
                            UBL2_1_xml_formatted_document += '<cbc:ID schemeID="UN/ECE 5153" schemeAgencyID="6">OTH</cbc:ID>';
                            UBL2_1_xml_formatted_document += '</cac:TaxScheme>';
                            UBL2_1_xml_formatted_document += '</cac:TaxCategory>';
                            UBL2_1_xml_formatted_document += '</cac:TaxSubtotal>';
                        }
                        //Exemption Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && (TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase() || TaxLine.TaxType.toLowerCase() == "Services".toLowerCase())
                            && Tax == 0)
                        {
                            UBL2_1_xml_formatted_document += '<cac:TaxSubtotal>';
                            UBL2_1_xml_formatted_document += '<cbc:TaxableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + BaseAmountInclusiveDiscount + '</cbc:TaxableAmount>';
                            UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + 0 + '</cbc:TaxAmount>';
                            UBL2_1_xml_formatted_document += '<cbc:BaseUnitMeasure unitCode="' + that._generateIRBMUOMCode(detail.UOM) + '">' + detail.Qty + '</cbc:BaseUnitMeasure>';
                            UBL2_1_xml_formatted_document += '<cbc:PerUnitAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + 0 + '</cbc:PerUnitAmount>';
                            UBL2_1_xml_formatted_document += '<cac:TaxCategory>';
                            UBL2_1_xml_formatted_document += '<cbc:ID>E</cbc:ID>'; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                            UBL2_1_xml_formatted_document += '<cbc:TaxExemptionReason>Exempted Taxable Goods</cbc:TaxExemptionReason>';
                            UBL2_1_xml_formatted_document += '<cac:TaxScheme>';
                            UBL2_1_xml_formatted_document += '<cbc:ID schemeID="UN/ECE 5153" schemeAgencyID="6">OTH</cbc:ID>';
                            UBL2_1_xml_formatted_document += '</cac:TaxScheme>';
                            UBL2_1_xml_formatted_document += '</cac:TaxCategory>';
                            UBL2_1_xml_formatted_document += '</cac:TaxSubtotal>';
                        }
                        //Tax Not Applicable
                        else
                        {
                            UBL2_1_xml_formatted_document += '<cac:TaxSubtotal>';
                            UBL2_1_xml_formatted_document += '<cbc:TaxableAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + BaseAmountInclusiveDiscount + '</cbc:TaxableAmount>';
                            UBL2_1_xml_formatted_document += '<cbc:TaxAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + 0 + '</cbc:TaxAmount>';
                            UBL2_1_xml_formatted_document += '<cbc:BaseUnitMeasure unitCode="' + that._generateIRBMUOMCode(detail.UOM) + '">' + detail.Qty + '</cbc:BaseUnitMeasure>';
                            UBL2_1_xml_formatted_document += '<cbc:PerUnitAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + 0 + '</cbc:PerUnitAmount>';
                            UBL2_1_xml_formatted_document += '<cac:TaxCategory>';
                            UBL2_1_xml_formatted_document += '<cbc:ID>06</cbc:ID>'; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                            UBL2_1_xml_formatted_document += '<cac:TaxScheme>';
                            UBL2_1_xml_formatted_document += '<cbc:ID schemeID="UN/ECE 5153" schemeAgencyID="6">OTH</cbc:ID>';
                            UBL2_1_xml_formatted_document += '</cac:TaxScheme>';
                            UBL2_1_xml_formatted_document += '</cac:TaxCategory>';
                            UBL2_1_xml_formatted_document += '</cac:TaxSubtotal>';
                        }

                        UBL2_1_xml_formatted_document += '</cac:TaxTotal>';

                        UBL2_1_xml_formatted_document += '<cac:Item>';
                        UBL2_1_xml_formatted_document += '<cbc:Description>' + (that._isNullOrWhitespace(that._sanitiseInput(detail.Remarks)) ? "-" : that._sanitiseInput(detail.Remarks).substring(0, 300)) + '</cbc:Description>';
                        UBL2_1_xml_formatted_document += '<cac:CommodityClassification>';
                        UBL2_1_xml_formatted_document += '<cbc:ItemClassificationCode listID="CLASS">' + that._sanitiseInput(detail.Classification) + '</cbc:ItemClassificationCode>';
                        UBL2_1_xml_formatted_document += '</cac:CommodityClassification>';
                        UBL2_1_xml_formatted_document += '</cac:Item>';

                        UBL2_1_xml_formatted_document += '<cac:Price>';
                        UBL2_1_xml_formatted_document += '<cbc:PriceAmount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + detail.UnitPrice + '</cbc:PriceAmount>';
                        UBL2_1_xml_formatted_document += '</cac:Price>';

                        UBL2_1_xml_formatted_document += '<cac:ItemPriceExtension>';
                        UBL2_1_xml_formatted_document += '<cbc:Amount currencyID="' + that._generateIRBMCurrencyCode(document.CurrCode) + '">' + BaseAmountExclusiveDiscount + '</cbc:Amount>';
                        UBL2_1_xml_formatted_document += '</cac:ItemPriceExtension>';

                        UBL2_1_xml_formatted_document += '</cac:InvoiceLine>';

                        index = index + 1;
                    }
                });

                UBL2_1_xml_formatted_document += '</Invoice>';

                //console.log(UBL2_1_xml_formatted_document);

                return UBL2_1_xml_formatted_document;
            }
            catch (err)
            {
                if (typeof err === "string")
                    throw err;
                else
                    throw "Unexpected Formatting Error."
            }
        },

        /**
         * Format document to IRBM-acceptable JSON format.
         *
         * @param {unformatted_document} document - Document to be formatted into IRBM acceptable JSON format.
         * @param {Date} issueDate - Issue Date, optional, default to new date if not pass in
         * @returns {string}
         */
        _formatJSONDocument: function (document, issueDate)
        {
            let that = this;

            //e-invoice document generation time / IRBM's e-invoice submission time
            issueDate = ((typeof issueDate != "undefined") && (issueDate instanceof Date)) ? issueDate : new Date(Date.now());

            try
            {
                that._validateDocument(document);

                //header
                let UBL2_1_json_formatted_document = {};

                UBL2_1_json_formatted_document["_D"] = "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2";
                UBL2_1_json_formatted_document["_A"] = "urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2";
                UBL2_1_json_formatted_document["_B"] = "urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2";
                UBL2_1_json_formatted_document["_E"] = "urn:oasis:names:specification:ubl:schema:xsd:CommonExtensionComponents-2";

                //invoice header
                UBL2_1_json_formatted_document["Invoice"] = [];
                UBL2_1_json_formatted_document["Invoice"][0] = {};

                UBL2_1_json_formatted_document["Invoice"][0]["ID"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["ID"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["ID"][0]["_"] = "(" + document.OUCode + ")" + document.DocNum; //prepend OUCode to ensure uniqueness, this should be same as codeNumber below
                UBL2_1_json_formatted_document["Invoice"][0]["IssueDate"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["IssueDate"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["IssueDate"][0]["_"] = issueDate.toISOString().split('T')[0];
                UBL2_1_json_formatted_document["Invoice"][0]["IssueTime"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["IssueTime"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["IssueTime"][0]["_"] = issueDate.toISOString().split('T')[1].split('.')[0] + "Z";

                UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["listVersionID"] = that._authObject.versionID;

                // Type of Invoice                  Mapping                                                     Explanation
                // 01 - Invoice                     Sales Invoice (SIE, SIC, SI)                                Seller (you) to issue invoice
                // 02 - Credit Note                 Sales Credit Note (CN) applies SIE, SIC, SI                 Seller (you) want to drop invoice price
                // 03 - Debit Note                  Sales Debit Note (DN) applies SIE, SIC, SI                  Seller (you) want to raise invoice price
                // 04 - Refund Note                 Sales Credit Note (CN with Refund) applies SIE, SIC, SI     Seller (you) want to refund buyer(not you)
                // 11 - Self-billed Invoice         Self-Billed Invoice (SB)                                    Buyer (you) to issue self-billed as Seller (not you) not able to do so
                // 12 - Self-billed Credit Note     Purchase Credit Note (CN) applies SB                        Buyer (you) to issue self-billed as Seller (not you) not able to do so  Seller (not you) want to raise invoice price
                // 13 - Self-billed Debit Note      Purchase Debit Note (DN) applies SB                         Buyer (you) to issue self-billed as Seller (not you) not able to do so  Seller (not you) want to drop invoice price
                // 14 - Self-billed Refund Note     Purchase Debit Note (DN with Refund) applies SB             Buyer (you) to issue self-billed as Seller (not you) not able to do so  Buyer (you) receive refund from seller (not you)

                //Invoice is a commercial document issued by Supplier to itemise and record a transaction with Buyer.
                if (document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC")
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["_"] = "01";
                }
                //Credit note is the document issued by Suppliers to correct errors, apply discounts, or account for returns in a previously issued e-Invoice with the purpose of reducing the value of the original e-Invoice. This is used in situations where the reduction of the original e-Invoice does not involve return of monies to the Buyer.
                else if (document.Source == "CN")
                {
                    if (document.CDType == "PUR")
                    {
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["_"] = "12";
                    }
                    else
                    {
                        if ((typeof document.IsRefund == "boolean") && document.IsRefund == true)
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["_"] = "04";
                        }
                        else
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["_"] = "02";
                        }
                    }
                }
                //Debit note is the document issued to indicate additional charges on a previously issued e-Invoice.
                else if (document.Source == "DN")
                {
                    if (document.CDType == "PUR")
                    {
                        if ((typeof document.IsRefund == "boolean") && document.IsRefund == true)
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["_"] = "14";
                        }
                        else
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["_"] = "13";
                        }
                    }
                    else
                    {
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["_"] = "03";
                    }
                }
                //There are certain circumstances where another party (other than the Supplier) will be allowed to issue a self-billed e-Invoice. Kindly refer to Section 8 of the e-Invoice Specific Guideline where self-billed e-Invoice will be allowed.
                //Self - Billed Invoice refers to the initial self - billed e - Invoice that will be issued by the Buyer.
                else if (document.Source == "SB")
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["InvoiceTypeCode"][0]["_"] = "11";
                }

                UBL2_1_json_formatted_document["Invoice"][0]["DocumentCurrencyCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["DocumentCurrencyCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["DocumentCurrencyCode"][0]["_"] = that._generateIRBMCurrencyCode(document.CurrCode);
                UBL2_1_json_formatted_document["Invoice"][0]["TaxCurrencyCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxCurrencyCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxCurrencyCode"][0]["_"] = "MYR"; //Quarto's tax line always MYR

                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"] = [];
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0] = {};
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["StartDate"] = [];
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["StartDate"][0] = {};
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["StartDate"][0]["_"] = new Date(document.InvoiceDate).toISOString().split('T')[0];
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["EndDate"] = [];
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["EndDate"][0] = {};
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["EndDate"][0]["_"] = new Date(document.InvoiceDate).toISOString().split('T')[0];
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["Description"] = [];
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["Description"][0] = {};
                //UBL2_1_json_formatted_document["Invoice"][0]["InvoicePeriod"][0]["Description"][0]["_"] = "Not Applicable";

                //References
                UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][0]["AdditionalDocumentReference"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][0]["AdditionalDocumentReference"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][0]["AdditionalDocumentReference"][0]["ID"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][0]["AdditionalDocumentReference"][0]["ID"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][0]["AdditionalDocumentReference"][0]["ID"][0]["_"] = ((that._isNullOrWhitespace(document.InvNum) || document.InvNum.trim().toUpperCase() == "NA") ? "NA" : document.InvNum.trim());

                //Invoice is a commercial document issued by Supplier to itemise and record a transaction with Buyer.
                if (document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC")
                {
                    //do not require this section
                }
                //Credit note is the document issued by Suppliers to correct errors, apply discounts, or account for returns in a previously issued e-Invoice with the purpose of reducing the value of the original e-Invoice. This is used in situations where the reduction of the original e-Invoice does not involve return of monies to the Buyer.
                else if (document.Source == "CN")
                {
                    if (document.Details.some(x => !that._isNullOrWhitespace(x.RefDocNum) && x.RefDocNum.trim().toUpperCase() != 'NA'))
                    {
                        let index = 1;
                        document.Details.filter(x => !that._isNullOrWhitespace(x.RefDocNum) && x.RefDocNum.trim().toUpperCase() != 'NA').reduce((prev, curr) =>
                        {
                            if (prev.every(z => z.RefDocNum != curr.RefDocNum.trim()))
                            {
                                prev.push({
                                    RefDocNum: curr.RefDocNum.trim(),
                                    RefIRBMNum: ((that._isNullOrWhitespace(curr.RefIRBMNum) || curr.RefIRBMNum.trim().toUpperCase() == 'NA') ? 'NA' : curr.RefIRBMNum)
                                });
                            }

                            return prev;
                        }, []).forEach(y =>
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["ID"][0]["_"] = ("(" + document.OUCode + ")" + y.RefDocNum); //prepend OUCode to ensure uniqueness
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["UUID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["UUID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["UUID"][0]["_"] = y.RefIRBMNum;

                            index = index + 1;
                        });
                    }
                    else
                    {
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["ID"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["ID"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["ID"][0]["_"] = 'NA';
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["UUID"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["UUID"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["UUID"][0]["_"] = 'NA';
                    }
                }
                //Debit note is the document issued to indicate additional charges on a previously issued e-Invoice.
                else if (document.Source == "DN")
                {
                    if (document.Details.some(x => !that._isNullOrWhitespace(x.RefDocNum) && x.RefDocNum.trim().toUpperCase() != 'NA'))
                    {
                        let index = 1;
                        document.Details.filter(x => !that._isNullOrWhitespace(x.RefDocNum) && x.RefDocNum.trim().toUpperCase() != 'NA').reduce((prev, curr) =>
                        {
                            if (prev.every(z => z.RefDocNum != curr.RefDocNum.trim()))
                            {
                                prev.push({
                                    RefDocNum: curr.RefDocNum.trim(),
                                    RefIRBMNum: ((that._isNullOrWhitespace(curr.RefIRBMNum) || curr.RefIRBMNum.trim().toUpperCase() == 'NA') ? 'NA' : curr.RefIRBMNum)
                                });
                            }

                            return prev;
                        }, []).forEach(y =>
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["ID"][0]["_"] = ("(" + document.OUCode + ")" + y.RefDocNum); //prepend OUCode to ensure uniqueness
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["UUID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["UUID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][index]["InvoiceDocumentReference"][0]["UUID"][0]["_"] = y.RefIRBMNum;

                            index = index + 1;
                        });
                    }
                    else
                    {
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["ID"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["ID"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["ID"][0]["_"] = 'NA';
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["UUID"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["UUID"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["BillingReference"][1]["InvoiceDocumentReference"][0]["UUID"][0]["_"] = 'NA';
                    }
                }
                //There are certain circumstances where another party (other than the Supplier) will be allowed to issue a self-billed e-Invoice. Kindly refer to Section 8 of the e-Invoice Specific Guideline where self-billed e-Invoice will be allowed.
                //Self - Billed Invoice refers to the initial self - billed e - Invoice that will be issued by the Buyer.
                else if (document.Source == "SB")
                {
                    //do not require this section
                }

                //Supplier
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0] = {};

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["IndustryClassificationCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["IndustryClassificationCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["IndustryClassificationCode"][0]["_"] = that._sanitiseInput(document.SupplierMSIC);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["IndustryClassificationCode"][0]["name"] = that._sanitiseInput(document.SupplierMSICDescription);

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][0]["ID"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][0]["ID"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][0]["ID"][0]["_"] = that._sanitiseInput(document.SupplierTIN);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][0]["ID"][0]["schemeID"] = "TIN";

                //must have either one
                if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierBRNo)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["_"] = that._sanitiseInput(document.SupplierBRNo);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["schemeID"] = "BRN";
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierNRIC)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["_"] = that._sanitiseInput(document.SupplierNRIC);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["schemeID"] = "NRIC";
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierPassportNo)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["_"] = that._sanitiseInput(document.SupplierPassportNo);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["schemeID"] = "PASSPORT";
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierArmyNo)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["_"] = that._sanitiseInput(document.SupplierArmyNo);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["schemeID"] = "ARMY";
                }
                else
                {
                    throw "Validation Error.";
                }

                //SST
                if (!that._isNullOrWhitespace(that._sanitiseInput(document.SupplierSST)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0]["_"] = that._sanitiseInput(document.SupplierSST);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0]["schemeID"] = "SST";
                }
                else
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0]["_"] = "NA";
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0]["schemeID"] = "SST";
                }

                //TT
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][3] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][3]["ID"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][3]["ID"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][3]["ID"][0]["_"] = "NA";
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyIdentification"][3]["ID"][0]["schemeID"] = "TTX";

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["CityName"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["CityName"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["CityName"][0]["_"] = that._isNullOrWhitespace(that._sanitiseInput(document.SupplierAddressCity)) ? 'NA' : that._sanitiseInput(document.SupplierAddressCity);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["PostalZone"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["PostalZone"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["PostalZone"][0]["_"] = that._isNullOrWhitespace(that._sanitiseInput(document.SupplierAddressPostCode)) ? 'NA' : that._sanitiseInput(document.SupplierAddressPostCode);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["CountrySubentityCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["CountrySubentityCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["CountrySubentityCode"][0]["_"] = that._generateIRBMStateCode(that._sanitiseInput(document.SupplierAddressState));
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"][0]["Line"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"][0]["Line"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"][0]["Line"][0]["_"] = that._isNullOrWhitespace(that._sanitiseInput(document.SupplierAddressStreet)) ? 'NA' : that._sanitiseInput(document.SupplierAddressStreet);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["Country"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"][0]["_"] = that._generateIRBMCountryCode(that._sanitiseInput(document.SupplierAddressCountry));
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"][0]["listID"] = "ISO3166-1";
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"][0]["listAgencyID"] = "6";

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyLegalEntity"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyLegalEntity"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyLegalEntity"][0]["RegistrationName"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyLegalEntity"][0]["RegistrationName"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["PartyLegalEntity"][0]["RegistrationName"][0]["_"] = that._sanitiseInput(document.SupplierName).substring(0, 300);

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["Contact"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["Contact"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["Contact"][0]["Telephone"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["Contact"][0]["Telephone"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["Contact"][0]["Telephone"][0]["_"] = that._isValidE164PhoneNumber(document.SupplierPhone) ? that._extractValidE164PhoneNumber(document.SupplierPhone) : 'NA';
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["Contact"][0]["ElectronicMail"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["Contact"][0]["ElectronicMail"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingSupplierParty"][0]["Party"][0]["Contact"][0]["ElectronicMail"][0]["_"] = that._isValidRFC5321Email(that._sanitiseInput(document.SupplierEmail)) ? that._sanitiseInput(document.SupplierEmail) : 'NA';

                //Buyer
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0] = {};

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][0]["ID"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][0]["ID"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][0]["ID"][0]["_"] = that._sanitiseInput(document.BuyerTIN);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][0]["ID"][0]["schemeID"] = "TIN";

                //must have either one
                if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerBRNo)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["_"] = that._sanitiseInput(document.BuyerBRNo);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["schemeID"] = "BRN";
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerNRIC)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["_"] = that._sanitiseInput(document.BuyerNRIC);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["schemeID"] = "NRIC";
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerPassportNo)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["_"] = that._sanitiseInput(document.BuyerPassportNo);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["schemeID"] = "PASSPORT";
                }
                else if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerArmyNo)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["_"] = that._sanitiseInput(document.BuyerArmyNo);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][1]["ID"][0]["schemeID"] = "ARMY";
                }
                else
                {
                    throw "Validation Error.";
                }

                //SST
                if (!that._isNullOrWhitespace(that._sanitiseInput(document.BuyerSST)))
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0]["_"] = that._sanitiseInput(document.BuyerSST);
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0]["schemeID"] = "SST";
                }
                else
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0]["_"] = "NA";
                    UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyIdentification"][2]["ID"][0]["schemeID"] = "SST";
                }

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["CityName"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["CityName"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["CityName"][0]["_"] = that._isNullOrWhitespace(that._sanitiseInput(document.BuyerAddressCity)) ? 'NA' : that._sanitiseInput(document.BuyerAddressCity);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["PostalZone"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["PostalZone"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["PostalZone"][0]["_"] = that._isNullOrWhitespace(that._sanitiseInput(document.BuyerAddressPostCode)) ? 'NA' : that._sanitiseInput(document.BuyerAddressPostCode);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["CountrySubentityCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["CountrySubentityCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["CountrySubentityCode"][0]["_"] = that._generateIRBMStateCode(that._sanitiseInput(document.BuyerAddressState));
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"][0]["Line"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"][0]["Line"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["AddressLine"][0]["Line"][0]["_"] = that._isNullOrWhitespace(that._sanitiseInput(document.BuyerAddressStreet)) ? 'NA' : that._sanitiseInput(document.BuyerAddressStreet);
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["Country"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"][0]["_"] = that._generateIRBMCountryCode(that._sanitiseInput(document.BuyerAddressCountry));
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"][0]["listID"] = "ISO3166-1";
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PostalAddress"][0]["Country"][0]["IdentificationCode"][0]["listAgencyID"] = "6";

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyLegalEntity"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyLegalEntity"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyLegalEntity"][0]["RegistrationName"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyLegalEntity"][0]["RegistrationName"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["PartyLegalEntity"][0]["RegistrationName"][0]["_"] = that._sanitiseInput(document.BuyerName).substring(0, 300);

                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["Contact"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["Contact"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["Contact"][0]["Telephone"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["Contact"][0]["Telephone"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["Contact"][0]["Telephone"][0]["_"] = that._isValidE164PhoneNumber(document.BuyerPhone) ? that._extractValidE164PhoneNumber(document.BuyerPhone) : 'NA';
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["Contact"][0]["ElectronicMail"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["Contact"][0]["ElectronicMail"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["AccountingCustomerParty"][0]["Party"][0]["Contact"][0]["ElectronicMail"][0]["_"] = that._isValidRFC5321Email(that._sanitiseInput(document.BuyerEmail)) ? that._sanitiseInput(document.BuyerEmail) : 'NA';

                //Payment method
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"] = [];
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0] = {};
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0]["PaymentMeansCode"] = [];
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0]["PaymentMeansCode"][0] = {};
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0]["PaymentMeansCode"][0]["_"] = "08"; //Payment Mode
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0]["PayeeFinancialAccount"] = [];
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0]["PayeeFinancialAccount"][0] = {};
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0]["PayeeFinancialAccount"][0]["ID"] = [];
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0]["PayeeFinancialAccount"][0]["ID"][0] = {};
                //UBL2_1_json_formatted_document["Invoice"][0]["PaymentMeans"][0]["PayeeFinancialAccount"][0]["ID"][0]["_"] = "Supplier bank account here";

                //Payment Terms and Condition
                UBL2_1_json_formatted_document["Invoice"][0]["PaymentTerms"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["PaymentTerms"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["PaymentTerms"][0]["Note"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["PaymentTerms"][0]["Note"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["PaymentTerms"][0]["Note"][0]["_"] = that._sanitiseInput(document.PayTermDesc).substring(0, 300);

                // Types of amount :
                //      1. Allowance (Discount)                => LegalMonetaryTotal > AllowanceTotalAmount
                //      2. Prepaid (Advance Payment)           => PrepaidPayment > PaidAmount
                //      3. Rounding                            => LegalMonetaryTotal > PayableRoundingAmount
                //      4. Charge (Fee) ie. Processing Fee     => LegalMonetaryTotal > ChargeTotalAmount
                //      5. VAT                                 => TaxTotal > TaxAmount
                //
                // Types of summation :
                //      1. LegalMonetaryTotal > LineExtensionAmount => Summation of invoice detail
                //                                                     ✔ Allowance (Discount)
                //                                                     ✘ Prepaid (Advance Payment)
                //                                                     ✘ Rounding
                //                                                     ✔ Charge (Fee)
                //                                                     ✘ VAT
                //      2. LegalMonetaryTotal > TaxExclusiveAmount  => Summation of invoice detail plus header level but Quarto don't have header level amount so it is same as LineExtensionAmount
                //                                                     ✔ Allowance (Discount)
                //                                                     ✘ Prepaid (Advance Payment)
                //                                                     ✘ Rounding
                //                                                     ✔ Charge (Fee)
                //                                                     ✘ VAT
                //      3. LegalMonetaryTotal > TaxInclusiveAmount  => TaxExclusiveAmount plus VAT
                //                                                     ✔ Allowance (Discount)
                //                                                     ✘ Prepaid (Advance Payment)
                //                                                     ✘ Rounding
                //                                                     ✔ Charge (Fee)
                //                                                     ✔ VAT
                //      4. LegalMonetaryTotal > PayableAmount       => Summation of everything
                //                                                     ✔ Allowance (Discount)
                //                                                     ✔ Prepaid (Advance Payment)
                //                                                     ✔ Rounding
                //                                                     ✔ Charge (Fee)
                //                                                     ✔ VAT

                //flip if account receivable side
                let IsFlip = document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC" || document.Source == "DN";

                //is contain prepayment
                let IsAdvance = (document.Source == "SI" || document.Source == "SIE" || document.Source == "SIC") && document.Details.some(detail => (typeof detail.IsAdvance == "boolean") && detail.IsAdvance);

                let TotalBaseAmountInclusiveDiscount = document.Details.reduce((n, detail) =>
                {
                    let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                    //if not tax, rounding, vendor line, prepayment
                    if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V") && !(IsAdvance && (typeof detail.IsAdvance == "boolean") && detail.IsAdvance))
                    {
                        return n.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                    }
                    else
                    {
                        return n;
                    }
                }, 0);

                let TotalBaseDiscount = document.Details.reduce((n, detail) =>
                {
                    let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                    //if not tax, rounding, vendor line, prepayment
                    if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V") && !(IsAdvance && (typeof detail.IsAdvance == "boolean") && detail.IsAdvance))
                    {
                        //discount aways store as positive, need to flip accordingly
                        if (IsFlip && detail.OrigTransAmt >= 0)
                        {
                            return n.Nadd(detail.DiscountAmt.Nflip());
                        }
                        else if (IsFlip && detail.OrigTransAmt < 0)
                        {
                            return n.Nadd(detail.DiscountAmt);
                        }
                        else if (!IsFlip && detail.OrigTransAmt >= 0)
                        {
                            return n.Nadd(detail.DiscountAmt);
                        }
                        else if (!IsFlip && detail.OrigTransAmt < 0)
                        {
                            return n.Nadd(detail.DiscountAmt.Nflip());
                        }
                    }
                    else
                    {
                        return n;
                    }
                }, 0);

                //Sales Tax
                let TotalSalesBaseAmount = 0;
                let TotalSalesTax = 0;
                let TotalSalesTaxCount = 0;

                //Service Tax
                let TotalServiceBaseAmount = 0;
                let TotalServiceTax = 0;
                let TotalServiceTaxCount = 0;

                //Exemption Tax
                let TotalExemptionBaseAmount = 0;
                let TotalExemptionTax = 0;
                let TotalExemptionTaxCount = 0;

                //Rounding
                let TotalRounding = 0;

                //Fee
                let TotalCharges = 0;

                //Prepaid
                let TotalPrepaid = 0;

                document.Details.forEach(detail =>
                {
                    let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                    //if base line (not tax, rounding, vendor line)
                    if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V"))
                    {
                        //Tax Line
                        let TaxLine = document.Details.find(x => x.RefTransDetKey == detail.TransDetKey && ((typeof x.Ind == "string") ? x.Ind : "").startsWith("T"));

                        //Prepayment (Tax amount from prepayment is consider part of prepayment)
                        if (IsAdvance && (typeof detail.IsAdvance == "boolean") && detail.IsAdvance)
                        {
                            //prepayment is in opposite nature
                            TotalPrepaid = TotalPrepaid.Nadd(IsFlip ? detail.OrigTransAmt : detail.OrigTransAmt.Nflip());

                            if (typeof TaxLine?.TaxType == "string"
                                && TaxLine.OrigTransAmt != 0)
                            {
                                TotalPrepaid = TotalPrepaid.Nadd(IsFlip ? TaxLine.OrigTransAmt : TaxLine.OrigTransAmt.Nflip());
                            }
                        }
                        //Sales Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase()
                            && TaxLine.OrigTransAmt != 0)
                        {
                            TotalSalesBaseAmount = TotalSalesBaseAmount.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                            TotalSalesTax = TotalSalesTax.Nadd(IsFlip ? TaxLine.OrigTransAmt.Nflip() : TaxLine.OrigTransAmt);
                            TotalSalesTaxCount = TotalSalesTaxCount + 1;
                        }
                        //Service Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && TaxLine.TaxType.toLowerCase() == "Services".toLowerCase()
                            && TaxLine.OrigTransAmt != 0)
                        {
                            TotalServiceBaseAmount = TotalServiceBaseAmount.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                            TotalServiceTax = TotalServiceTax.Nadd(IsFlip ? TaxLine.OrigTransAmt.Nflip() : TaxLine.OrigTransAmt);
                            TotalServiceTaxCount = TotalServiceTaxCount + 1;
                        }
                        //Exemption Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && (TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase() || TaxLine.TaxType.toLowerCase() == "Services".toLowerCase())
                            && TaxLine.OrigTransAmt == 0)
                        {
                            TotalExemptionBaseAmount = TotalExemptionBaseAmount.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                            TotalExemptionTax = 0;
                            TotalExemptionTaxCount = TotalExemptionTaxCount + 1;
                        }
                    }
                    //if rounding line
                    else if (Ind.startsWith("R"))
                    {
                        TotalRounding = TotalRounding.Nadd(IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt);
                    }
                });

                //Prepaid or Advance Payment
                if (IsAdvance)
                {
                    let AdvanceLine = document.Details.find(detail => (typeof detail.IsAdvance == "boolean") && detail.IsAdvance);

                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["ID"][0]["_"] = ((that._isNullOrWhitespace(AdvanceLine?.AdvanceDocNum) || AdvanceLine.AdvanceDocNum.trim().toUpperCase() == 'NA') ? 'NA' : ("(" + document.OUCode + ")" + AdvanceLine.AdvanceDocNum.trim()));
                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidAmount"][0]["_"] = TotalPrepaid;
                    UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);

                    //kendo won't parse nested object, date will appear as string in nested object, need to manually validate with momentjs
                    if ((moment(AdvanceLine?.AdvanceInvoiceDate, "YYYY-MM-DDTHH:mm:ss", true)).isValid()
                        && ((new Date(AdvanceLine?.AdvanceInvoiceDate)).getFullYear() > 1900
                            && (new Date(AdvanceLine?.AdvanceInvoiceDate)).getFullYear() < 2999))
                    {
                        if (AdvanceLine.AdvanceInvoiceDate instanceof Date)
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidDate"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidDate"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidDate"][0]["_"] = AdvanceLine.AdvanceInvoiceDate.toISOString().split('T')[0];
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidTime"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidTime"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidTime"][0]["_"] = AdvanceLine.AdvanceInvoiceDate.toISOString().split('T')[1].split('.')[0] + "Z";
                        }
                        else if (typeof AdvanceLine.AdvanceInvoiceDate == "string")
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidDate"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidDate"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidDate"][0]["_"] = AdvanceLine.AdvanceInvoiceDate.split('T')[0];
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidTime"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidTime"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["PrepaidPayment"][0]["PaidTime"][0]["_"] = AdvanceLine.AdvanceInvoiceDate.split('T')[1].split('.')[0] + "Z";
                        }
                    }
                }

                //Tax Exchange Rate
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["SourceCurrencyCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["SourceCurrencyCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["SourceCurrencyCode"][0]["_"] = that._generateIRBMCurrencyCode(document.CurrCode);
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["TargetCurrencyCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["TargetCurrencyCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["TargetCurrencyCode"][0]["_"] = "MYR";
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["CalculationRate"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["CalculationRate"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["CalculationRate"][0]["_"] = document.ExRateFunc.NtoDP(6);
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["MathematicOperatorCode"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["MathematicOperatorCode"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["MathematicOperatorCode"][0]["_"] = document.ConvertType == "M" ? '*' : '/';
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["Date"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["Date"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxExchangeRate"][0]["Date"][0]["_"] = new Date(document.GLDate).toISOString().split('T')[0];

                //Taxes Summation
                UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxAmount"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxAmount"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxAmount"][0]["_"] = TotalSalesTax.Nadd(TotalServiceTax);
                UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);

                if (TotalSalesTaxCount > 0)
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"] ??= []; let index = UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"].length;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0]["_"] = TotalSalesBaseAmount;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0]["_"] = TotalSalesTax;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"][0]["_"] = "01"; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["_"] = "OTH";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeID"] = "UN/ECE 5153";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeAgencyID"] = "6";
                }

                if (TotalServiceTaxCount > 0)
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"] ??= []; let index = UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"].length;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0]["_"] = TotalServiceBaseAmount;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0]["_"] = TotalServiceTax;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"][0]["_"] = "02"; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["_"] = "OTH";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeID"] = "UN/ECE 5153";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeAgencyID"] = "6";
                }

                if (TotalExemptionTaxCount > 0)
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"] ??= []; let index = UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"].length;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0]["_"] = TotalExemptionBaseAmount;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0]["_"] = TotalExemptionTax;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"][0]["_"] = "E"; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxExemptionReason"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxExemptionReason"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxExemptionReason"][0]["_"] = "Exempted Taxable Goods";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["_"] = "OTH";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeID"] = "UN/ECE 5153";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeAgencyID"] = "6";
                }

                if (TotalSalesTaxCount == 0 && TotalServiceTaxCount == 0 && TotalExemptionTaxCount == 0)
                {
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"] ??= []; let index = UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"].length;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0]["_"] = TotalBaseAmountInclusiveDiscount;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0]["_"] = 0;
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["ID"][0]["_"] = "06"; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"] = [];
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0] = {};
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["_"] = "OTH";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeID"] = "UN/ECE 5153";
                    UBL2_1_json_formatted_document["Invoice"][0]["TaxTotal"][0]["TaxSubtotal"][index]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeAgencyID"] = "6";
                }

                //Summations
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["LineExtensionAmount"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["LineExtensionAmount"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["LineExtensionAmount"][0]["_"] = TotalBaseAmountInclusiveDiscount.Nadd(TotalCharges);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["LineExtensionAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["TaxExclusiveAmount"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["TaxExclusiveAmount"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["TaxExclusiveAmount"][0]["_"] = TotalBaseAmountInclusiveDiscount.Nadd(TotalCharges);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["TaxExclusiveAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["TaxInclusiveAmount"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["TaxInclusiveAmount"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["TaxInclusiveAmount"][0]["_"] = TotalBaseAmountInclusiveDiscount.Nadd(TotalCharges).Nadd(TotalSalesTax).Nadd(TotalServiceTax);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["TaxInclusiveAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["AllowanceTotalAmount"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["AllowanceTotalAmount"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["AllowanceTotalAmount"][0]["_"] = TotalBaseDiscount;
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["AllowanceTotalAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["ChargeTotalAmount"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["ChargeTotalAmount"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["ChargeTotalAmount"][0]["_"] = TotalCharges;
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["ChargeTotalAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["PayableRoundingAmount"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["PayableRoundingAmount"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["PayableRoundingAmount"][0]["_"] = TotalRounding;
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["PayableRoundingAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["PayableAmount"] = [];
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["PayableAmount"][0] = {};
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["PayableAmount"][0]["_"] = TotalBaseAmountInclusiveDiscount.Nadd(TotalCharges).Nadd(TotalSalesTax).Nadd(TotalServiceTax).Nadd(TotalRounding).Nminus(TotalPrepaid);
                UBL2_1_json_formatted_document["Invoice"][0]["LegalMonetaryTotal"][0]["PayableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);

                //Invoice Line
                UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"] = [];

                let index = 0;
                document.Details.forEach((detail) =>
                {
                    let Ind = (typeof detail.Ind == "string") ? detail.Ind : "";

                    //if base line (not tax, rounding, vendor line, prepayment)
                    if (!Ind.startsWith("T") && !Ind.startsWith("R") && !Ind.startsWith("V") && !(IsAdvance && (typeof detail.IsAdvance == "boolean") && detail.IsAdvance))
                    {
                        //add back discounted amount to get back amount before discount
                        let BaseAmountExclusiveDiscount = (IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt).Nadd(detail.DiscountAmt);
                        let BaseDiscount = detail.DiscountAmt;
                        let BaseAmountInclusiveDiscount = IsFlip ? detail.OrigTransAmt.Nflip() : detail.OrigTransAmt;

                        //Tax Line
                        let TaxLine = document.Details.find(x => x.RefTransDetKey == detail.TransDetKey && ((typeof x.Ind == "string") ? x.Ind : "").startsWith("T"));
                        let Tax = (typeof TaxLine?.TaxType == "string" && (TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase() || TaxLine.TaxType.toLowerCase() == "Services".toLowerCase())) ? (IsFlip ? TaxLine.OrigTransAmt.Nflip() : TaxLine.OrigTransAmt) : 0;

                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ID"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ID"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ID"][0]["_"] = (index + 1).toString();
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["InvoicedQuantity"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["InvoicedQuantity"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["InvoicedQuantity"][0]["_"] = detail.Qty;
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["InvoicedQuantity"][0]["unitCode"] = that._generateIRBMUOMCode(detail.UOM);
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["LineExtensionAmount"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["LineExtensionAmount"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["LineExtensionAmount"][0]["_"] = BaseAmountInclusiveDiscount;
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["LineExtensionAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);

                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["ChargeIndicator"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["ChargeIndicator"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["ChargeIndicator"][0]["_"] = false;
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["AllowanceChargeReason"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["AllowanceChargeReason"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["AllowanceChargeReason"][0]["_"] = "Discount Applied";
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["Amount"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["Amount"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["Amount"][0]["_"] = BaseDiscount;
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["AllowanceCharge"][0]["Amount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);

                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxAmount"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxAmount"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxAmount"][0]["_"] = Tax;
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);

                        //Sales Tax
                        if (typeof TaxLine?.TaxType == "string"
                            && TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase()
                            && Tax != 0)
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0]["_"] = BaseAmountInclusiveDiscount;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0]["_"] = Tax;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0]["_"] = detail.Qty;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0]["unitCode"] = that._generateIRBMUOMCode(detail.UOM);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0]["_"] = Tax.Ndiv2(detail.Qty);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"][0]["_"] = "01"; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["_"] = "OTH";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeID"] = "UN/ECE 5153";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeAgencyID"] = "6";
                        }
                        //Service Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && TaxLine.TaxType.toLowerCase() == "Services".toLowerCase()
                            && Tax != 0)
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0]["_"] = BaseAmountInclusiveDiscount;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0]["_"] = Tax;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0]["_"] = detail.Qty;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0]["unitCode"] = that._generateIRBMUOMCode(detail.UOM);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0]["_"] = Tax.Ndiv2(detail.Qty);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"][0]["_"] = "02"; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["_"] = "OTH";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeID"] = "UN/ECE 5153";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeAgencyID"] = "6";
                        }
                        //Exemption Tax
                        else if (typeof TaxLine?.TaxType == "string"
                            && (TaxLine.TaxType.toLowerCase() == "Sales".toLowerCase() || TaxLine.TaxType.toLowerCase() == "Services".toLowerCase())
                            && Tax == 0)
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0]["_"] = BaseAmountInclusiveDiscount;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0]["_"] = 0;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0]["_"] = detail.Qty;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0]["unitCode"] = that._generateIRBMUOMCode(detail.UOM);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0]["_"] = 0;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"][0]["_"] = "E"; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxExemptionReason"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxExemptionReason"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxExemptionReason"][0]["_"] = "Exempted Taxable Goods";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["_"] = "OTH";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeID"] = "UN/ECE 5153";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeAgencyID"] = "6";
                        }
                        //Tax Not Applicable
                        else
                        {
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0]["_"] = BaseAmountInclusiveDiscount;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxableAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0]["_"] = 0;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0]["_"] = detail.Qty;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["BaseUnitMeasure"][0]["unitCode"] = that._generateIRBMUOMCode(detail.UOM);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0]["_"] = 0;
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["PerUnitAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["ID"][0]["_"] = "06"; //Sales Tax (01), Service Tax(02), Tourism Tax(03), Not Applicable(06), Tax exemption(E)
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"] = [];
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0] = {};
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["_"] = "OTH";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeID"] = "UN/ECE 5153";
                            UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["TaxTotal"][0]["TaxSubtotal"][0]["TaxCategory"][0]["TaxScheme"][0]["ID"][0]["schemeAgencyID"] = "6";
                        }

                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["Description"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["Description"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["Description"][0]["_"] = that._isNullOrWhitespace(that._sanitiseInput(detail.Remarks)) ? "-" : that._sanitiseInput(detail.Remarks).substring(0, 300);
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["CommodityClassification"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["CommodityClassification"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["CommodityClassification"][0]["ItemClassificationCode"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["CommodityClassification"][0]["ItemClassificationCode"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["CommodityClassification"][0]["ItemClassificationCode"][0]["_"] = that._sanitiseInput(detail.Classification);
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Item"][0]["CommodityClassification"][0]["ItemClassificationCode"][0]["listID"] = "CLASS";

                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Price"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Price"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Price"][0]["PriceAmount"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Price"][0]["PriceAmount"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Price"][0]["PriceAmount"][0]["_"] = detail.UnitPrice;
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["Price"][0]["PriceAmount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);

                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ItemPriceExtension"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ItemPriceExtension"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ItemPriceExtension"][0]["Amount"] = [];
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ItemPriceExtension"][0]["Amount"][0] = {};
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ItemPriceExtension"][0]["Amount"][0]["_"] = BaseAmountExclusiveDiscount;
                        UBL2_1_json_formatted_document["Invoice"][0]["InvoiceLine"][index]["ItemPriceExtension"][0]["Amount"][0]["currencyID"] = that._generateIRBMCurrencyCode(document.CurrCode);

                        index = index + 1;
                    }
                });

                return JSON.stringify(UBL2_1_json_formatted_document);
            }
            catch (err)
            {
                if (typeof err === "string")
                    throw err;
                else
                    throw "Unexpected Formatting Error."
            }
        },

        /**
         * Take in unformmatted list of documents, process it by formatting it to IRBM-acceptable format and submit it to IRBM.
         * @typedef {Object} accepted
         * @property {number} TransHdrKey - Document's TransHdrKey.
         * @property {string} IRBMNum - IRBM Number given by IRBM.
         * @property {string} FileUploaded - JSON stringify formatted file submitted to IRBM.
         *
         * @typedef {Object} rejected
         * @property {number} TransHdrKey - Document's TransHdrKey.
         * @property {string} Error - Error Description.
         * @property {string} FileUploaded - JSON stringify formatted file submitted to IRBM.
         *
         * @typedef {Object} submission
         * @property {string} SubmissionUID - Unique ID of the submission. 26 Latin alphanumeric symbols.
         * @property {string} State - Submission state.
         * @property {accepted[]} Success - IRBM accepted list.
         * @property {rejected[]} Failed - IRBM rejected list.
         *
         * @param {string} clientTIN - Client TIN.
         * @param {unformatted_document[]} documents - List of documents to process.
         * @param {string} format - XML or JSON, default to XML.
         * @param {Date} issueDate - Issue Date, optional, default to new date if not pass in
         * @returns {Promise<submission>}
         */
        _processDocument: function (clientTIN, documents, format, issueDate)
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                /** @type { container[] } */
                let generated_documents = [];
                /** @type { formatted_document[] } */
                let processed_documents = [];
                /** @type { accepted[] } */
                let accepted_documents = [];
                /** @type { rejected[] } */
                let error_documents = [];

                if (!Array.isArray(documents) || documents.length == 0)
                {
                    resolve({
                        State: "Selected document(s) is invalid.",
                        SubmissionUID: null,
                        Failed: error_documents,
                        Success: []
                    });
                }
                else if (that._isNullOrWhitespace(clientTIN))
                {
                    resolve({
                        State: "Invalid Input. Company TIN is missing.",
                        SubmissionUID: null,
                        Failed: error_documents,
                        Success: []
                    });
                }
                else if (typeof issueDate != "undefined" && !(issueDate instanceof Date))
                {
                    resolve({
                        State: "Invalid issue date.",
                        SubmissionUID: null,
                        Failed: error_documents,
                        Success: []
                    });
                }
                else
                {
                    issueDate = ((typeof issueDate != "undefined") && (issueDate instanceof Date)) ? issueDate : new Date(Date.now());

                    documents.forEach(document =>
                    {
                        try
                        {
                            generated_documents.push({
                                content: (typeof format == "string" && format == "JSON") ? that._formatJSONDocument(document, issueDate) : that._formatXMLDocument(document, issueDate),
                                codeNumber: "(" + document.OUCode + ")" + document.DocNum,
                                format: (typeof format == "string" && format == "JSON") ? "JSON" : "XML"
                            });
                        }
                        catch (error)
                        {
                            error_documents.push({
                                TransHdrKey: document.TransHdrKey,
                                Error: error,
                                FileUploaded: ""
                            });
                        }
                    });

                    //if there is any error prior to submit, reject whole batch to avoid potential issue
                    if (error_documents.length > 0)
                    {
                        resolve({
                            State: "Selected document(s) failed validation/verification.",
                            SubmissionUID: null,
                            Failed: error_documents,
                            Success: []
                        });
                    }
                    else
                    {
                        //if pass formatting, check for duplicates, OUCode + DocNum should be unique at all time
                        if ((new Set(documents.map(x => ("(" + x.OUCode + ")" + x.DocNum)))).size != documents.length)
                        {
                            resolve({
                                State: "Selected document(s) contain duplicated Document Number.",
                                SubmissionUID: null,
                                Failed: [],
                                Success: []
                            });
                        }
                        else
                        {
                            if (false)
                            {
                                resolve({
                                    State: "E-Invoice submission starts on August 1, 2024.",
                                    SubmissionUID: null,
                                    Failed: [],
                                    Success: []
                                });
                            }
                            else
                            {
                                //digitally sign the generated document
                                that._generateSignature(generated_documents).then((signed_documents) =>
                                {
                                    processed_documents = signed_documents.map((signed_document) =>
                                    {
                                        return {
                                            format: (typeof format == "string" && format == "JSON") ? "JSON" : "XML",
                                            document: btoa(signed_document.content),
                                            documentHash: that._generateHashFromString(signed_document.content, "hex"),
                                            codeNumber: signed_document.codeNumber
                                        };
                                    });

                                    that._submitDocument(clientTIN, processed_documents).then(
                                        result =>
                                        {
                                            if (Array.isArray(result.acceptedDocuments) && result.acceptedDocuments.length > 0)
                                            {
                                                result.acceptedDocuments.forEach(document =>
                                                {
                                                    accepted_documents.push({
                                                        TransHdrKey: documents.find(x => ("(" + x.OUCode + ")" + x.DocNum) == document.invoiceCodeNumber).TransHdrKey,
                                                        IRBMNum: document.uuid,
                                                        FileUploaded: typeof processed_documents.find(x => x.codeNumber == document.invoiceCodeNumber) != "undefined" ? JSON.stringify(processed_documents.find(x => x.codeNumber == document.invoiceCodeNumber)) : ""
                                                    });
                                                });
                                            }

                                            if (Array.isArray(result.rejectedDocuments) && result.rejectedDocuments.length > 0)
                                            {
                                                result.rejectedDocuments.forEach(document =>
                                                {
                                                    error_documents.push({
                                                        TransHdrKey: documents.find(x => ("(" + x.OUCode + ")" + x.DocNum) == document.invoiceCodeNumber).TransHdrKey,
                                                        Error:
                                                            typeof document?.error == "string" ? document.error :
                                                                typeof document?.error?.message == "string" ? (document.error.message + (typeof document?.error?.details?.[0]?.message == "string" ? ("(" + document.error.details[0].message + ")") : ""))
                                                                    : "Unknown Error",
                                                        FileUploaded: typeof processed_documents.find(x => x.codeNumber == document.invoiceCodeNumber) != "undefined" ? JSON.stringify(processed_documents.find(x => x.codeNumber == document.invoiceCodeNumber)) : ""
                                                    });
                                                })
                                            }

                                            if (error_documents.length > 0)
                                            {
                                                resolve({
                                                    State: accepted_documents.length > 0 ? "Selected document(s) was successfully submitted but some was rejected by IRBM." : "Selected document(s) was rejected by IRBM.",
                                                    SubmissionUID: result.submissionUid,
                                                    Failed: error_documents,
                                                    Success: accepted_documents
                                                });
                                            }
                                            else
                                            {
                                                resolve({
                                                    State: "Selected document(s) was successfully submitted.",
                                                    SubmissionUID: result.submissionUid,
                                                    Failed: error_documents,
                                                    Success: accepted_documents
                                                });
                                            }
                                        },
                                        error =>
                                        {
                                            resolve({
                                                State:
                                                    typeof error?.error?.details?.[0]?.message == "string" ? error.error.details[0].message :
                                                        typeof error?.error?.message == "string" ? error.error.message :
                                                            typeof error?.message == "string" ? error.message :
                                                                typeof error == "string" ? error
                                                                    : "Error encountered while connecting to IRBM server."
                                                ,
                                                SubmissionUID: null,
                                                Failed: [],
                                                Success: []
                                            });
                                        });
                                }, () =>
                                {
                                    resolve({
                                        State: "Error encountered while attempting to digitally sign document.",
                                        SubmissionUID: null,
                                        Failed: [],
                                        Success: []
                                    });
                                });
                            }
                        }
                    }
                }
            });
        },

        /**
         * Cancel a submitted document, action usually done by Supplier/Seller except when it is Self-Billed where you are submitting as Customer/Buyer on behalf of Supplier/Seller
         * @param {string} clientTIN - Client TIN.
         * @param {string} IRBMNum - IRBM Number given by IRBM.
         * @param {string} Reason - Reason for cancellation.
         * @returns {Promise<void>}
         */
        _cancelDocument: function (clientTIN, IRBMNum, Reason)
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN) || that._isNullOrWhitespace(IRBMNum))
                {
                    reject("Invalid Input.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/CancelDocument?token=' + that._authObject.token + '&IRBMNum=' + IRBMNum,
                            method: "PUT",
                            retry: false,
                            data: JSON.stringify(JSON.stringify({
                                'status': 'cancelled',
                                'reason': that._isNullOrWhitespace(Reason) ? '-' : Reason
                            }))
                        }).then((result) =>
                        {
                            if (typeof result?.data == "undefined")
                            {
                                reject("Invalid response from IRBM server.");
                            }
                            else
                            {
                                resolve(result.data);
                            }
                        }, (error) =>
                        {
                            reject(
                                (typeof error?.data?.error?.details?.[0]?.message == "string") ? error?.data?.error?.details?.[0]?.message :
                                    (typeof error?.data?.message == "string") ? error?.data?.message :
                                        (typeof error?.data == "string") ? error?.data : "Failed"
                            );
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },

        /**
         * Reject a submitted document, action usually done by Customer/Buyer, after this the Supplier/Seller should send a cancel request else this rejection is ignored. Not applicable to Self-Billed as Customer/Buyer can cancel it directly.
         * @param {string} clientTIN - Client TIN.
         * @param {string} IRBMNum - IRBM Number given by IRBM.
         * @param {string} Reason - Reason for cancellation.
         * @returns {Promise<void>}
         */
        _rejectDocument: function (clientTIN, IRBMNum, Reason)
        {
            // TODO : don't allow to reject if it is Self-Billed as Customer/Buyer can directly cancel.
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN) || that._isNullOrWhitespace(IRBMNum))
                {
                    reject("Invalid Input.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/CancelDocument?token=' + that._authObject.token + '&IRBMNum=' + IRBMNum,
                            method: "PUT",
                            retry: false,
                            data: JSON.stringify(JSON.stringify({
                                'status': 'rejected',
                                'reason': that._isNullOrWhitespace(Reason) ? '-' : Reason
                            }))
                        }).then((result) =>
                        {
                            if (typeof result?.data == "undefined")
                            {
                                reject("Invalid response from IRBM server.");
                            }
                            else
                            {
                                resolve(result.data);
                            }
                        }, (error) =>
                        {
                            reject(error?.data);
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },

        /**
         * Get a list of recently submitted documents by page no from IRBM server
         * @param {string} clientTIN - Client TIN.
         * @param {number} pageNo - Page No (Optional).
         * @param {number} pageSize - Page Size (Optional).
         * @param {Date} submissionDateFrom - E-invoice Submission Date filter from (Optional).
         * @param {Date} submissionDateTo - E-invoice Submission Date filter to (Optional).
         * @param {Date} issueDateFrom - E-invoice Issue Date filter from (Optional).
         * @param {Date} issueDateTo - E-invoice Issue Date filter to (Optional).
         * @param {string} direction - Direction of the e-invoice (Sent, Received) (Optional).
         * @param {string} status - Status of the e-invoice (Valid, Invalid, Cancelled, Submitted) (Optional).
         * @param {string} documentType - Document Type (01, 02, 03, 04, 11, 12, 13, 14) (Optional).
         * @param {string} receiverId - Receipient ID, can be BR. No, IC No, Army ID, only applicable when direction is Sent (Optional).
         * @param {string} receiverIdType - Receipient ID Type (BRN, PASSPORT, NRIC, ARMY), applicable only when receiverId is proviced (Optional).
         * @param {string} receiverTin - Receipient TIN, only applicable when direction is Sent (Optional).
         * @param {string} issuerTin - Issuer TIN, only applicable when direction is Received (Optional).
         *
         * @typedef {Object} searchResult - Search result.
         * @property {string} uuid - IRBMNum in e-Invoice.
         * @property {string} submissionUID - Unique ID of the submission. 26 Latin alphanumeric symbols.
         * @property {string} longId - Unique long temporary Id that can be used to query document data anonymously.
         * @property {string} internalId - Internal ID used in submission for the document.
         * @property {string} typeName - Unique name of the document type that can be used in submission of the documents.
         * @property {string} typeVersionName - Name of the document type version within the document type that can be used in document submission to identify document type version being submitted.
         * @property {string} issuerTin - TIN of issuer.
         * @property {string} issuerName - Issuer company name.
         * @property {string} receiverId - Optional: receiver registration number (can be national ID or foreigner ID).
         * @property {string} receiverIdType - Optional : Receipient ID Type (BRN, PASSPORT, NRIC, ARMY).
         * @property {string} receiverName - Optional: receiver name (can be company name or person’s name).
         * @property {Date} dateTimeIssued - The date and time when the document was issued.
         * @property {Date} dateTimeReceived - The date and time when the document was submitted.
         * @property {Date} dateTimeValidated - The date and time when the document passed all validations and moved to the valid state.
         * @property {number} totalSales - Total sales amount of the document in MYR.
         * @property {number} totalDiscount - Total discount amount of the document in MYR.
         * @property {number} netAmount - Total net amount of the document in MYR.
         * @property {number} total - Total amount of the document in MYR.
         * @property {string} status - Status of the document - Submitted, Valid, Invalid, Cancelled.
         * @property {Date} cancelDateTime - Refer to the document cancellation that has been initiated by the taxpayer “issuer” of the document on the system, will be in UTC format.
         * @property {Date} rejectRequestDateTime - Refer to the document rejection request that has been initiated by the taxpayer “receiver” of the document on the system, will be in UTC format.
         * @property {string} documentStatusReason - Mandatory: Reason of the cancellation or rejection of the document.
         * @property {string} createdByUserId - User created the document. Can be ERP ID or User Email.
         *
         * @returns {Promise<searchResult[]>} //TODO, the return structure in IRBM documentation is confusing
         */
        _getRecentDocument: function (
            clientTIN,
            pageNo,
            pageSize,
            submissionDateFrom,
            submissionDateTo,
            issueDateFrom,
            issueDateTo,
            direction,
            status,
            documentType,
            receiverId,
            receiverIdType,
            receiverTin,
            issuerTin
        )
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN))
                {
                    reject("Invalid Input. Company TIN is missing.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        let query = '';

                        //if page size is set, then set page no as well, else ignore
                        if (typeof pageSize == "number" && pageSize > 0 && pageSize < 100)
                        {
                            if (typeof pageNo == "number" && pageNo > 0)
                            {
                                query = (query == '') ? query : (query + '&');
                                query = query + 'pageNo=' + pageNo;
                            }
                            else
                            {
                                query = (query == '') ? query : (query + '&');
                                query = query + 'pageNo=' + 1; //default to first page
                            }

                            query = (query == '') ? query : (query + '&');
                            query = query + 'pageSize=' + pageSize;
                        }

                        if (submissionDateFrom instanceof Date)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'submissionDateFrom=' + submissionDateFrom.toISOString();
                        }

                        if (submissionDateTo instanceof Date)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'submissionDateTo=' + submissionDateTo.toISOString();
                        }

                        if (issueDateFrom instanceof Date)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'issueDateFrom=' + issueDateFrom.toISOString();
                        }

                        if (issueDateTo instanceof Date)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'issueDateTo=' + issueDateTo.toISOString();
                        }

                        if (!that._isNullOrWhitespace(direction) && (direction == "Sent" || direction == "Received"))
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'direction=' + direction;
                        }

                        if (!that._isNullOrWhitespace(status) && (status == "Valid" || status == "Invalid" || status == "Cancelled" || status == "Submitted"))
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'status=' + status;
                        }

                        if (!that._isNullOrWhitespace(documentType) && ["01", "02", "03", "04", "11", "12", "13", "14"].indexOf(documentType) >= 0)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'documentType=' + documentType;
                        }

                        //these are one pair, construct only if both valid
                        if (!that._isNullOrWhitespace(receiverIdType) && !that._isNullOrWhitespace(receiverId) && !that._isNullOrWhitespace(direction) && direction == "Sent")
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'receiverId=' + receiverId;

                            query = (query == '') ? query : (query + '&');
                            query = query + 'receiverIdType=' + receiverIdType;
                        }

                        if (!that._isNullOrWhitespace(receiverTin) && !that._isNullOrWhitespace(direction) && direction == "Sent")
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'receiverTin=' + receiverTin;
                        }

                        if (!that._isNullOrWhitespace(issuerTin) && !that._isNullOrWhitespace(direction) && direction == "Received")
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'issuerTin=' + issuerTin;
                        }

                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/RecentDocument?token=' + that._authObject.token + (query != '' ? ('&query=' + encodeURIComponent(query)) : ''),
                            method: "GET",
                            retry: false
                        }).then((result) =>
                        {
                            if (typeof result?.data == "undefined")
                            {
                                reject("Invalid response from IRBM server.");
                            }
                            else
                            {
                                resolve(result.data);
                            }
                        }, (error) =>
                        {
                            reject(error?.data);
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },

        /**
         * Get a list of documents by submissionUID from IRBM server.
         *
         * @param {string} clientTIN - Client TIN.
         * @param {string} submissionUID - Unique ID of the submission. 26 Latin alphanumeric symbols.
         * @param {number} pageNo - Page No (Optional).
         * @param {number} pageSize - Page Size (Optional).
         *
         * @typedef {Object} submissionResult
         * @property {string} submissionUID - Unique ID of the submission. 26 Latin alphanumeric symbols.
         * @property {number} documentCount - Total count of documents in submission that were accepted for processing.
         * @property {date} dateTimeReceived - The date and time when the submission was received by e-Invoice.
         * @property {string} overallStatus - Overall status of the batch processing. Values: in progress, valid, partially valid, invalid.
         * @property {searchResult[]} documentSummary - List of the retrieved batch documents in current page.
         *
         * @returns {Promise<submissionResult>}
         */
        _getSubmission: function (clientTIN, submissionUID, pageNo, pageSize)
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN) || that._isNullOrWhitespace(submissionUID))
                {
                    reject("Invalid Input.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        let query = '';

                        //if page size is set, then set page no as well, else ignore
                        if (typeof pageSize == "number" && pageSize > 0 && pageSize < 100)
                        {
                            if (typeof pageNo == "number" && pageNo > 0)
                            {
                                query = (query == '') ? query : (query + '&');
                                query = query + 'pageNo=' + pageNo;
                            }
                            else
                            {
                                query = (query == '') ? query : (query + '&');
                                query = query + 'pageNo=' + 1; //default to first page
                            }

                            query = (query == '') ? query : (query + '&');
                            query = query + 'pageSize=' + pageSize;
                        }

                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/Submission?token=' + that._authObject.token + '&submissionUID=' + submissionUID + (query != '' ? ('&query=' + encodeURIComponent(query)) : ''),
                            method: "GET",
                            retry: false
                        }).then((result) =>
                        {
                            if (typeof result?.data == "undefined")
                            {
                                reject("Invalid response from IRBM server.");
                            }
                            else
                            {
                                resolve(result.data);
                            }
                        }, (error) =>
                        {
                            reject(error?.data);
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },

        /**
         * Get a document by IRBMNum from IRBM server.
         *
         * @param {string} clientTIN - Client TIN.
         * @param {string} IRBMNum - Unique ID of the submission. 26 Latin alphanumeric symbols.
         *
         * @typedef {searchResult & {document:formatted_document}} searchDocument
         *
         * @returns {Promise<searchDocument>}
         */
        _getDocument: function (clientTIN, IRBMNum)
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN) || that._isNullOrWhitespace(IRBMNum))
                {
                    reject("Invalid Input.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/Document?token=' + that._authObject.token + '&IRBMNum=' + IRBMNum,
                            method: "GET",
                            retry: false
                        }).then((result) =>
                        {
                            if (typeof result?.data == "undefined")
                            {
                                reject("Invalid response from IRBM server.");
                            }
                            else
                            {
                                resolve(result.data);
                            }
                        }, (error) =>
                        {
                            reject(error?.data);
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },

        /**
         * Get a document detail by IRBMNum from IRBM server.
         *
         * @param {string} clientTIN - Client TIN.
         * @param {string} IRBMNum - Unique ID of the submission. 26 Latin alphanumeric symbols.
         *
         * @typedef {Object} documentValidationStepResult - Validation results for each of the validation steps that has been scheduled.
         * @property {string} name - Validation name from definition of all system validations.
         * @property {string} status - Validation status. Values: Submitted, Valid, Invalid
         * @property {Object} error - Optional: validation error structure containing one or more errors if status is Invalid
         *
         * @typedef {Object} documentValidationResult - Object structure containing full validation results of the document.
         * @property {string} status - Validation status. Values: Submitted, Valid, Invalid
         * @property {documentValidationStepResult[]} validationSteps - Validation results for each of the validation steps that has been scheduled.
         *
         * @typedef {searchResult & {validationResults:documentValidationResult}} searchDocumentDetail
         *
         * @returns {Promise<searchDocumentDetail>}
         */
        _getDocumentDetail: function (clientTIN, IRBMNum)
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN) || that._isNullOrWhitespace(IRBMNum))
                {
                    reject("Invalid Input.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/DocumentDetail?token=' + that._authObject.token + '&IRBMNum=' + IRBMNum,
                            method: "GET",
                            retry: false
                        }).then((result) =>
                        {
                            if (typeof result?.data == "undefined")
                            {
                                reject("Invalid response from IRBM server.");
                            }
                            else
                            {
                                resolve(result.data);
                            }
                        }, (error) =>
                        {
                            reject(error?.data);
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },

        /**
         * Get a list of recently submitted documents by continuation token from IRBM server
         * @param {string} clientTIN - Client TIN.
         * @param {string} continuationToken - Continuation Token, omit this for page 1 (Optional).
         * @param {number} pageSize - Page Size (Optional).
         * @param {Date} submissionDateFrom - E-invoice Submission Date filter from (Optional).
         * @param {Date} submissionDateTo - E-invoice Submission Date filter to (Optional).
         * @param {Date} issueDateFrom - E-invoice Issue Date filter from (Optional).
         * @param {Date} issueDateTo - E-invoice Issue Date filter to (Optional).
         * @param {string} direction - Direction of the e-invoice (Sent, Received) (Optional).
         * @param {string} status - Status of the e-invoice (Valid, Invalid, Cancelled, Submitted) (Optional).
         * @param {string} documentType - Document Type (01, 02, 03, 04, 11, 12, 13, 14) (Optional).
         * @param {string} receiverId - Receipient ID, can be BR. No, IC No, Army ID, only applicable when direction is Sent (Optional).
         * @param {string} receiverIdType - Receipient ID Type (BRN, PASSPORT, NRIC, ARMY), applicable only when receiverId is proviced (Optional).
         * @param {string} issuerTin - Issuer TIN, only applicable when direction is Received (Optional).
         *
         * @returns {Promise<searchResult[]>} //TODO, the return structure in IRBM documentation is confusing
         */
        _getRecentDocumentByToken: function (
            clientTIN,
            continuationToken,
            pageSize,
            submissionDateFrom,
            submissionDateTo,
            issueDateFrom,
            issueDateTo,
            direction,
            status,
            documentType,
            receiverId,
            receiverIdType,
            issuerTin
        )
        {
            let that = this;

            return new Promise((resolve, reject) =>
            {
                if (that._isNullOrWhitespace(clientTIN))
                {
                    reject("Invalid Input. Company TIN is missing.");
                }
                else
                {
                    //check if auth-ed
                    let Auth = that._isAuth(clientTIN) ? Promise.resolve() : that._requestAuth(clientTIN);

                    Auth.then(() =>
                    {
                        let query = '';

                        if (typeof pageSize == "number" && pageSize > 0 && pageSize < 100)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'pageSize=' + pageSize;
                        }

                        if (!that._isNullOrWhitespace(continuationToken))
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'continuationToken=' + continuationToken;
                        }

                        if (submissionDateFrom instanceof Date)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'submissionDateFrom=' + submissionDateFrom.toISOString();
                        }

                        if (submissionDateTo instanceof Date)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'submissionDateTo=' + submissionDateTo.toISOString();
                        }

                        if (issueDateFrom instanceof Date)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'issueDateFrom=' + issueDateFrom.toISOString();
                        }

                        if (issueDateTo instanceof Date)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'issueDateTo=' + issueDateTo.toISOString();
                        }

                        if (!that._isNullOrWhitespace(direction) && (direction == "Sent" || direction == "Received"))
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'direction=' + direction;
                        }

                        if (!that._isNullOrWhitespace(status) && (status == "Valid" || status == "Invalid" || status == "Cancelled" || status == "Submitted"))
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'status=' + status;
                        }

                        if (!that._isNullOrWhitespace(documentType) && ["01", "02", "03", "04", "11", "12", "13", "14"].indexOf(documentType) >= 0)
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'documentType=' + documentType;
                        }

                        //these are one pair, construct only if both valid
                        if (!that._isNullOrWhitespace(receiverIdType) && !that._isNullOrWhitespace(receiverId) && !that._isNullOrWhitespace(direction) && direction == "Sent")
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'receiverId=' + receiverId;

                            query = (query == '') ? query : (query + '&');
                            query = query + 'receiverIdType=' + receiverIdType;
                        }

                        if (!that._isNullOrWhitespace(issuerTin) && !that._isNullOrWhitespace(direction) && direction == "Received")
                        {
                            query = (query == '') ? query : (query + '&');
                            query = query + 'issuerTin=' + issuerTin;
                        }

                        $http({
                            url: $rootScope.financeWebApiUrl + 'EInvoice/RecentDocumentByToken?token=' + that._authObject.token + (query != '' ? ('&query=' + encodeURIComponent(query)) : ''),
                            method: "GET",
                            retry: false
                        }).then((result) =>
                        {
                            if (typeof result?.data == "undefined")
                            {
                                reject("Invalid response from IRBM server.");
                            }
                            else
                            {
                                resolve(result.data);
                            }
                        }, (error) =>
                        {
                            reject(error?.data);
                        });
                    }, () =>
                    {
                        reject("Unauthorized. To proceed, please authorize Quarto as an intermediary and grant necessary permissions (View, Submit, Cancel) through the MyInvois Portal.<br><br>ERP System Name : Quarto<br>Tax Identification Number : C4898308100<br>Business Registration Number : 199101001831");
                    });
                }
            });
        },
    };

    //documentation please refer to base function above ( click go to definition :D )
    //this is just a wrapper to guard internal service from being altered / screwed up
    return {
        IRBMServerDelay: eInvoiceServiceInternalFactory._authObject.IRBMServerDelay,
        DecodeToken: () => eInvoiceServiceInternalFactory._decodeToken(),
        ValidateEmail: (email) => eInvoiceServiceInternalFactory._isValidRFC5321Email(email),
        ValidatePhone: (phone) => eInvoiceServiceInternalFactory._isValidE164PhoneNumber(phone),
        ValidateTIN: (clientTIN, tin, idType, idValue) => eInvoiceServiceInternalFactory._validateTIN(clientTIN, tin, idType, idValue),
        ExtractPhone: (phone) => eInvoiceServiceInternalFactory._extractValidE164PhoneNumber(phone),
        CancelDocument: (clientTIN, IRBMNum, Reason) => eInvoiceServiceInternalFactory._cancelDocument(clientTIN, IRBMNum, Reason),
        RejectDocument: (clientTIN, IRBMNum, Reason) => eInvoiceServiceInternalFactory._rejectDocument(clientTIN, IRBMNum, Reason),
        ProcessDocument: (clientTIN, documents, issueDate) => eInvoiceServiceInternalFactory._processDocument(clientTIN, documents, "JSON", issueDate),
        GetRecentDocument: (
            clientTIN,
            pageNo,
            pageSize,
            submissionDateFrom,
            submissionDateTo,
            issueDateFrom,
            issueDateTo,
            direction,
            status,
            documentType,
            receiverId,
            receiverIdType,
            receiverTin,
            issuerTin
        ) => eInvoiceServiceInternalFactory._getRecentDocument(
            clientTIN,
            pageNo,
            pageSize,
            submissionDateFrom,
            submissionDateTo,
            issueDateFrom,
            issueDateTo,
            direction,
            status,
            documentType,
            receiverId,
            receiverIdType,
            receiverTin,
            issuerTin
        ),
        GetSubmission: (clientTIN, submissionUID, pageNo, pageSize) => eInvoiceServiceInternalFactory._getSubmission(clientTIN, submissionUID, pageNo, pageSize),
        GetDocument: (clientTIN, IRBMNum) => eInvoiceServiceInternalFactory._getDocument(clientTIN, IRBMNum),
        GetDocumentDetail: (clientTIN, IRBMNum) => eInvoiceServiceInternalFactory._getDocumentDetail(clientTIN, IRBMNum),
        GetRecentDocumentByToken: (
            clientTIN,
            continuationToken,
            pageSize,
            submissionDateFrom,
            submissionDateTo,
            issueDateFrom,
            issueDateTo,
            direction,
            status,
            documentType,
            receiverId,
            receiverIdType,
            issuerTin
        ) => eInvoiceServiceInternalFactory._getRecentDocumentByToken(
            clientTIN,
            continuationToken,
            pageSize,
            submissionDateFrom,
            submissionDateTo,
            issueDateFrom,
            issueDateTo,
            direction,
            status,
            documentType,
            receiverId,
            receiverIdType,
            issuerTin
        ),
    };
});